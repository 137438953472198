import React, { useEffect, useState } from "react";
import useHubContext from "../../hooks/useHubContext";
import { useSelector,shallowEqual } from "react-redux";
import { USER_TYPES } from "../../constants";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
  } from "@material-ui/core";
import useStyles from "./styles";
export default function UpdateNotification() {
    const [visible, setVisible] = useState(false);
    const currentUser = useSelector((state) => state.user, shallowEqual);
    const { Connected: PortalHubConnected, portalHub } = useHubContext();
    const handleCancel = () => {
        setVisible(false);
    }

    const handleConfirm = () => {
        window.location.reload(true);
    }

    useEffect(() => {
			if (portalHub.isOpen) {
				portalHub.subscribe(`PORTAL_UPDATE`, (payload) => {
                    if (currentUser.UserType !== USER_TYPES.ValidationAccount) {
                        setVisible(true);
                    }
				});
			}

			return () => portalHub.unsubscribe(`PORTAL_UPDATE`);
		}, [PortalHubConnected, portalHub.isOpen]);
    const classes = useStyles();
    return (
        <Dialog open={visible} onClose={handleCancel} className={classes.maxZIndex}>
            <DialogTitle>
                Update Available
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    There has been an update to this application, please refresh your browser.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary" variant="contained" startIcon={<RefreshIcon />}>
                    Refresh
                </Button>
                <Button onClick={handleCancel} color="secondary" variant="contained" startIcon={<CancelIcon />}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>);
}