// src/features/cart/cartSlice.js
import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import apiClient from "../../auth/apiClient";

import _ from "lodash";

const initialState = {
  UserID: null,
  Username: null,
  Firstname: null,
  Lastname: null,
  EmailAddress: null,
  FederatedID: null,
  Properties: null, //a list of properties, with the perms they have for each
  isAdmin: false,
};

const userSlice = createSlice({
  name: "user",
  // in case cart was saved items, will be here:
  initialState: initialState,
  // reducers actions
  reducers: {
    setUser: (state, action) => {
      state.UserID = action.payload.UserID;
      state.EmailAddress = action.payload.EmailAddress;
      state.Username = action.payload.Username;
      state.Firstname = action.payload.Firstname;
      state.Lastname = action.payload.Lastname;
      state.UserType = action.payload.UserType;
      state.isAdmin = action.payload.isAdmin;
      state.FederatedID = action.payload.FederatedID;
    },
    setUserID: (state, action) => {
      state.UserID = action.payload;
    },
    setUserEmail: (state, action) => {
      state.EmailAddress = action.payload;
    },
    setUsername: (state, action) => {
      state.Username = action.payload;
    },
    setFirstname: (state, action) => {
      state.Firstname = action.payload.trim();
    },
    setLastname: (state, action) => {
      state.Lastname = action.payload.trim();
    },
    setProperties: (state, action) => {
      state.Properties = action.payload;
    },
    setValidationAccounts: (state, action) => {
      state.ValidationAccounts = action.payload;
    },
    setUserType: (state, action) => {
      state.UserType = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    reset: () => initialState,
  },
});
//actions
export const {
  setUserID,
  setUserEmail,
  setUsername,
  setFirstname,
  setLastname,
  setProperties,
  setValidationAccounts,
  setUserType,
  setIsAdmin,
  reset,
  setUser
} = userSlice.actions;

export default userSlice.reducer;

//4aa9fbcb-e0df-4ea3-9976-de8590a809fb    --AMI
//e3e2b062-258a-4705-971b-583cc3096cfe    --bobs belcher lot
//5d6c2ba6-b699-4c1b-88e0-ad9087452309    --callibrity terminal
