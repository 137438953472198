import React, { useContext,createContext,useState, useEffect, memo } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

// if it cant fetch the flag then it should default to use old entity slice
const CoreEntityContext = createContext(false);

export const useCoreEntityContext = () => useContext(CoreEntityContext);

// The reason why im doing all of this state management is because I don't want the flag to change while the app is using the page
// This just means that if the feature flag was to ever change it would require the client to reload the page manually
export const CoreEntityProvider = memo(({ children }) => {
    const ldClient = useLDClient();
    const [initialValue, setInitialValue] = useState(undefined);

    useEffect(() => {
        if (ldClient) {
            const variation = ldClient.variation("kill-switch-core-entity-slice", false);
            setInitialValue((prevValue) => prevValue ?? variation); // Keep the first resolved value
        }
    }, [ldClient]);

    // Prevent rendering the provider until the initial value is resolved
    if (initialValue === undefined) return null;

    return (
        <CoreEntityContext.Provider value={initialValue}>
            {children}
        </CoreEntityContext.Provider>
    );
},{ displayName: 'CoreEntityProvider' });