import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import TicketService from "../../../services/TicketService";
import PropTypes from "prop-types";
import { Grid, ListItem, Card, CardContent, List } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../auth/apiClient";
import clsx from "clsx";
import SearchBar from "../../SearchBar";
import TicketValidationRow from "./TicketValidationRow";
import TicketAutoValidationRow from "./TicketAutoValidationRow";
import Typography from "@material-ui/core/Typography";
import useCancellationToken from "../../../hooks/useCancellationToken";
import _ from "lodash";
import useTimeout from "../../../hooks/useTimeout";
import { TICKET_SEARCH_TYPE } from "../../../constants";

const ticketService = new TicketService(apiClient);

export default function TicketValidations({
  facilityID,
  searchTermInput,
  validationOffers,
  autoApplyValidationOffer,
  isSendApplyValidationRunning,
  handleSendApplyValidationRunningChange,
  scopeAwareFacilityID,
  automaticallyApplyValidationEnabled,
  searchType,
  clearInputField,
  parsedFromCamera,
  handleParsedFromCameraChange,
  isOnePassView,
  onTicketClick
}) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState(searchTermInput);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [formattedTickets, setFormattedTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSendApplyValidationRunningChange = (value) => handleSendApplyValidationRunningChange(value);
  const searchTermChange = (value) => setSearchTerm(value);
  const onParsedFromCameraChange = (value) => handleParsedFromCameraChange(value);

  const {
    execute: executeTicketSearchQuery,
    cancel: cancelTicketSearchQuery,
    inProgress,
  } = useCancellationToken({
    func: getTickets,
    errHandleFunc: (err) => {
      if (err.response && err.response.status === 400 && typeof err.response.data === "string")
        enqueueSnackbar(err.response.data, { variant: "warning" });
      else
        enqueueSnackbar("Failed to retrieve tickets", {
          variant: "error",
          tag: "FailedToRetrieveTickets",
        });
    },
  });
  const { setExpirationDt, cancelTimeout } = useTimeout({
    onTimeout: () => {
      setSearchTerm("");
      onSendApplyValidationRunningChange(false);
      onParsedFromCameraChange(false);
    }
  });

  useEffect(() => {
    cancelTimeout();
    if (automaticallyApplyValidationEnabled && parsedFromCamera) {
      var t = new Date();
      t.setSeconds(t.getSeconds() + 3);
      setExpirationDt(t);
    }
  }, [formattedTickets]);

  useEffect(() => {
    cancelTimeout();
    if (searchTermInput) {
      setSearchTerm(searchTermInput);
    }
  }, [searchTermInput]);  

  useEffect(() => {
    if (clearInputField === true) setSearchTerm("")
  }, [searchType, clearInputField])

  useEffect(() => {
    setLoading(inProgress);
  }, [inProgress]);

  async function getTickets({ facilityID, searchTerm, searchType,cancelToken }) {
    onSendApplyValidationRunningChange(true);
    let response = await ticketService.searchForTicketsWithValidations({
      facilityID,
      searchTerm,
      searchType,
      cancelToken
    });

    const allTickets = response?.data?.collection ?? [];
    const openTickets = allTickets?.filter((ticket) => {
      const ticketStatus = ticket.status?.toLowerCase();
      if (
        !isOnePassView &&
        ticketStatus !== "exit" &&
        ticketStatus !== "out" &&
        ticketStatus !== "lost ticket" &&
        ticketStatus !== "backout with ticket" &&
        ticketStatus !== "backout without ticket"
      ) {
        return ticket;
      } else if(isOnePassView && 
        ticketStatus !== "exit" &&
        ticketStatus !== "out" &&
        ticketStatus !== "lost ticket" &&
        ticketStatus !== "backout with ticket" &&
        ticketStatus !== "backout without ticket" &&
        ticketStatus !== "mobile payment" && 
        ticketStatus !== "archived" &&
        ticketStatus !== "payment" &&
        ticketStatus !== "void"
      ) {
        return ticket
      }
    });
    if(isOnePassView && parsedFromCamera && openTickets.length > 0) {
      onTicketClick(openTickets[0]);
    } else {
      setFormattedTickets(formatTickets(openTickets));
    }
  }

  const filterOffers = (ticket) => {
    // don't filter out offers if we aren't in a FG
    if (facilityID === scopeAwareFacilityID) {
      return validationOffers;
    }

    let filteredOffers = [];
    if (validationOffers.length > 0) {
      validationOffers.forEach(vo => {
        if (vo.offers && vo.offers.some((x) => x.acceptedAtEntityIDs.includes(ticket.facilityID))) {
          filteredOffers.push({
            account: vo.account,
            offers: vo.offers.filter((x) => x.acceptedAtEntityIDs.includes(ticket.facilityID))
          });
        }
      });
    }
    return filteredOffers;
  }

  const formatTickets = (tickets) => {
    return tickets?.map((ticket, index) => {
      return (
        ticket !== undefined &&
        ticket.ticketID !== null &&
        ticket.ticketID !== undefined && (
          <ListItem
            key={index}
            className={clsx("ticket-row-item", classes.listItem)}
            onClick={() => isOnePassView && onTicketClick(ticket)}
          >
            <Card
              variant="outlined"
              data-id={`ticket-${index}`}
              className={clsx(classes.card, "ticket-card")}
            >
              <CardContent
                className={clsx("ticket-card-content", classes.cardContent)}
              >
                {!(automaticallyApplyValidationEnabled && parsedFromCamera) && (
                  <TicketValidationRow
                    validationOffers={filterOffers(ticket)}
                    ticket={ticket}
                    searchType={searchType}
                    setSearchTerm={searchTermChange}
                    autoApplyValidationOffer={autoApplyValidationOffer}
                    automaticallyApplyValidationEnabled={
                      automaticallyApplyValidationEnabled
                    }
                    isOnePassView={isOnePassView}
                  />
                )}
                {automaticallyApplyValidationEnabled && parsedFromCamera && (
                  <TicketAutoValidationRow
                    validationOffers={filterOffers(ticket)}
                    autoApplyValidationOffer={autoApplyValidationOffer}
                    ticket={ticket}
                    searchType={searchType}
                    onSendApplyValidationRunningChange={
                      onSendApplyValidationRunningChange
                    }
                    automaticallyApplyValidationEnabled={
                      automaticallyApplyValidationEnabled
                    }
                    onParsedFromCameraChange={onParsedFromCameraChange}
                    isOnePassView={isOnePassView}
                  />
                )}
              </CardContent>
            </Card>
          </ListItem>
        )
      );
    });
  };

  const handleSearchChange = (search) => {
    setSearchTerm(search);
    if (!search || _.isEmpty(search) || search.length < 3) {
      cancelTicketSearchQuery();
      setFormattedTickets([]);
      return;
    }
    executeTicketSearchQuery({ facilityID: scopeAwareFacilityID, searchTerm: search ,searchType: searchType});
  };

  return (
    <Grid
      container
      spacing={1}
      className={clsx("ticket-table", classes.ticketTable)}
    >
      <SearchBar
        className={clsx("pageable-search-bar")}
        placeholder={"Search..."}
        label={searchType === TICKET_SEARCH_TYPE.TICKET ? "Tickets" : "License Plates"}
        delay={250}
        onChange={handleSearchChange}
        value={searchTerm}
        data-id="ticket-searchbar"
        isLoading={loading}
        disabled={isSendApplyValidationRunning && automaticallyApplyValidationEnabled && parsedFromCamera}
      />
      {!searchTerm ? (
        <></>
      ) : formattedTickets?.length > 0 ? (
        <List className={classes.list}>{formattedTickets}</List>
      ) : (
        <Typography>No Ticket Found</Typography>
      )}
    </Grid>
  );
}

TicketValidations.propTypes = {
  searchTermInput: PropTypes.string,
  facilityID: PropTypes.string,
  autoApplyValidationOffer: PropTypes.string,
  isSendApplyValidationRunning: PropTypes.bool,
  validationOffers: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ),
  handleSendApplyValidationRunningChange: PropTypes.func,
  scopeAwareFacilityID: PropTypes.string,
  searchType: PropTypes.number,
  parsedFromCamera: PropTypes.bool,
  handleParsedFromCameraChange: PropTypes.func,
  isOnePassView: PropTypes.bool
};