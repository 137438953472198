import React, { useEffect, useCallback, useState } from "react";
import DownloadValidationsForm from "../../components/Forms/Validation/Download";
import ApplyValidationsPanel from "../../components/Panels/Validations/ApplyValidations";
import Loading from "../../components/Loading";
import { Typography, Grid,Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import useHasPermissions from "../../hooks/useHasPermissions";
import ValidationAccountService from "../../services/ValidationAccountService";
import AutomaticValidationService from "../../services/AutomaticValidationService";
import apiClient from "../../auth/apiClient";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";
import { faPrint,faTicket,faMobile } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidateOnePass from "../../components/OnePass/ValidateOnePass";
import { useCoreEntityContext } from "../../hooks/useCoreEntitySlice";
import ValidationUserOnePassService from "../../services/ValidationUserOnePassService";

const validationAccountService = new ValidationAccountService(apiClient);
const automaticValidationService = new AutomaticValidationService(apiClient);
const validationUserOnePassService = new ValidationUserOnePassService(apiClient);

const ValidatePage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const useCoreEntitySlice = useCoreEntityContext();
  const currentFacility = useSelector(state => useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || currentFacility);
  const currentUserID = useSelector(state => state.user?.UserID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [validationAccounts, setValidationAccounts] = useState();
  const [autoApplyValidationOffer, setAutoApplyValidationOffer] = useState();
  const [loading, setLoading] = useState(true);
  const [validationUserOnePassDetails, setValidationUserOnePassDetails] = useState();
  const { hasPermissions } = useHasPermissions();
  const PrintValidationPermission = hasPermissions([
    "createprintedvalidations"
  ]);
  const validateTicketsPermission = hasPermissions(["validatetickets"]);
  const onePassPermission = hasPermissions(["createonepass"]);
  const [automaticallyApplyValidationEnabled, setAutomaticallyApplyValidationEnabled] = useState(false);
  const isOnePassFeatureEnabled = useFeatureFlag("ONE Pass");
  const [selectedTabValue, setSelectedTabValue] = useState("");
  const [validationUserData, setValidationUserData] = useState([]);

  // Set default tab based on available permissionsv
  useEffect(() => {
    // Default tab should be the first available tab based on permissions
    if (validateTicketsPermission) {
      setSelectedTabValue("tabValidateTickets");
    } else if (PrintValidationPermission) {
      setSelectedTabValue("tabPrintValidation");
    } else if (onePassPermission && isOnePassFeatureEnabled) {
      setSelectedTabValue("tabOnePass");
    }
  }, [validateTicketsPermission, PrintValidationPermission, onePassPermission, isOnePassFeatureEnabled]);
  
  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "xl" });
  }, [layoutDispatch]);

  useEffect(() => {
    if (currentUserID && scopeAwareFacilityID) {
      getValidationAccountsForUser();
      if (isGuidNotAllZeros(currentUserID)) {
        getAutomaticValidation();
      }
    }
  }, [currentUserID, scopeAwareFacilityID]);

  useEffect(() => {
    if(selectedTabValue === "tabOnePass" && currentUserID && scopeAwareFacilityID) {
      if(isGuidNotAllZeros) getValidationUserOnePassDetails();
    }
  }, [selectedTabValue, currentUserID, scopeAwareFacilityID])

  function isGuidNotAllZeros(guid) {
    const pattern = /^[0]{8}-[0]{4}-[0]{4}-[0]{4}-[0]{12}$/;
    return !pattern.test(guid);
  }

  const getValidationAccountsForUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await validationAccountService.getValidationAccountsForUser(
        scopeAwareFacilityID, 
        currentUserID,
         true
        );
      setValidationAccounts(response.data.map(x => x.contractHolderID));
      setValidationUserData(response?.data || []);
    } catch (err) {
      enqueueSnackbar("Failed to retrieve validation account info", { 
        variant: "error", 
        tag: "FailedToRetrieveValidationAccountInfo" 
      });
    } finally {
      setLoading(false);
    }
  }, [scopeAwareFacilityID, currentUserID]);

  const getAutomaticValidation = useCallback(async () => {
    try {
      const response = await automaticValidationService.getAutomaticValidation(currentUserID, scopeAwareFacilityID);

        // NOTE: As of now only one Validation Account in a Facility can have an auto offer
        // However, there are plans to support more than one Validation Account per Facility but will
        // require UI changes.
      setAutoApplyValidationOffer(response.data?.[0]?.validationOfferID);
      setAutomaticallyApplyValidationEnabled(response.data?.length >= 1);
       } catch (err) {
        if (err?.response?.status !== 404) {
          enqueueSnackbar("Failed to retrieve automatic validation.", {
          variant: "error",
            tag: "FailedToRetrieveAutomaticValidation" 
          });
      }
    }
  }, 
  [currentUserID, scopeAwareFacilityID]
);

  const getValidationUserOnePassDetails = useCallback(async () => {
    try {
      const response = await validationUserOnePassService.getValidationUserOnePassDetails(currentUserID);
      setValidationUserOnePassDetails(response.data);
      } catch (err) {
        if (err?.response?.status !== 404) {
          enqueueSnackbar("Failed to retrieve onepass details.", {
          variant: "error",
            tag: "FailedToRetrieveOnePassDetails" 
          });
      }
    }
  }, [currentUserID, enqueueSnackbar]);

  const handleTabChange = (event, value) => {
    setSelectedTabValue(value);
  };

  const handleSendOnePass = (onePassDetails) => {
    // Send the ONE Pass details to the API logic will be added here once the API is ready
  }

  return (
    <Grid container>
      {loading ?  (
        <Grid item xs={12}>
          <Loading className={classes.spinner} data-testid = "loading-spinner" />
        </Grid>
      ) : validationAccounts?.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Tabs
              centered
              className={classes.validationTab}
              variant="fullWidth"
              value={selectedTabValue}
              onChange={handleTabChange}
            >
              {validateTicketsPermission && (
              <Tab
                value="tabValidateTickets"
                icon={<FontAwesomeIcon icon={faTicket} size="2xl"/>}
                label="Validate Tickets"
                data-testid="validate-tickets-tab"
              />)}
              {PrintValidationPermission && (
              <Tab
                value="tabPrintValidation"
                icon={<FontAwesomeIcon icon={faPrint} size="2xl" />}
                label="Printable Validations"
                data-testid="print-validations-tab"
              />
              )}
              {isOnePassFeatureEnabled && onePassPermission && (
                <Tab
                value="tabOnePass"
                icon={<FontAwesomeIcon icon={faMobile} size="2xl" />}
                label="ONE Pass"
                data-testid="one-pass-tab"
              />
              )}
            </Tabs>
          </Grid>
          {selectedTabValue === "tabValidateTickets" && (
            <Grid container className={classes.tabContent}>
              <Grid className={classes.contentContainer} item xs={12} md={6}>
                {validateTicketsPermission && (
                  <ApplyValidationsPanel
                    data-testid={"apply-val-panel"}
                    className={clsx(["apply-val-panel", classes.applyValidationPanel])}
                    facilityID={currentFacility}
                    contractHolderIDs={validationAccounts}
                    scopeAwareFacilityID={scopeAwareFacilityID}
                    autoApplyValidationOffer={autoApplyValidationOffer}
                    automaticallyApplyValidationEnabled={automaticallyApplyValidationEnabled}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {selectedTabValue === "tabPrintValidation" && (
            <Grid container className={classes.tabContent}>
              <Grid className={classes.contentContainer} item xs={12} md={8}>
                {PrintValidationPermission && (
                  <DownloadValidationsForm
                    data-testid={"print-val-form"}
                    className={clsx(["print-val-form", classes.printValidationForm])}
                    entityID={scopeAwareFacilityID}
                    contractHolderIDs={validationAccounts}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {selectedTabValue === "tabOnePass" && (
            <Grid container className={classes.tabContent}>
              <Grid className={classes.contentContainer} item xs={12} md={6}>
                <ValidateOnePass
                  automaticallyApplyValidationEnabled={
                    automaticallyApplyValidationEnabled
                  }
                  validationAccountOptions={validationUserData}
                  facilityID={currentFacility}
                  scopeAwareFacilityID={scopeAwareFacilityID}
                  className={clsx([
                    "onepass-panel",
                    classes.applyValidationPanel,
                  ])}
                  onePassDetails={validationUserOnePassDetails}
                  sendOnePass={handleSendOnePass}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Typography align="center" className={classes.noValidationAccountMessage}
            data-testid="no-validation-account-message">  
            You have no validation accounts associated with your user.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ValidatePage;
