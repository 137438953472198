import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Grid, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment-timezone";

import ValetInventoryTable from "./ValetInventoryTable/ValetInventoryTable";
import ValetInventorySearch from "./ValetInventorySearch/ValetInventorySearch";
import Title from "../Title";

// Hooks
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useCancellationToken from "../../hooks/useCancellationToken";
import useCurrentFacilityTimezone from "../../hooks/useCurrentFacilityTimezone";

// Services and API
import apiClient from "../../auth/apiClient";
import ValetInventoryService from "../../services/ValetInventoryService";

// Styles
import { useStyles } from "./ValetInventory.styles";

const ValetInventory = () => {
  const classes = useStyles();
  const pageSize = 10;

  // Initialize necessary services and hooks
  const valetinventoryService = new ValetInventoryService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID: entityID } = useCurrentFacility();
  const { timeZone } = useCurrentFacilityTimezone();

  // State management
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState([]);
  const [activeSearch, setActiveSearch] = useState({
    date: null,
    searchTerm: "",
    selectedContext: 0,
    selectedAge:1,
  });
  const [totalCount, setTotalCount] = useState(0);

  // Execute search query with cancellation
  const executeSearchQuery = useCancellationToken({
    func: async ({ cancelToken }) => {
      setLoading(true);
      try {
        const offset = (currentPage - 1) * pageSize;
        const searchDate = (activeSearch.selectedAge === 1) ? moment().tz(timeZone).subtract(1, 'day').tz('UTC').format("MM/DD/YYYY hh:mm:ss A").toString() : activeSearch.date;
        const res = await valetinventoryService.getValetInventorySearch(
          entityID,
          searchDate,
          activeSearch.searchTerm,
          activeSearch.selectedContext,
          pageSize,
          offset,
          cancelToken
        );

        setResults(res.data.collection || []);
        setTotalCount(res.data.totalCount || 0);
      } catch {
        enqueueSnackbar("Failed to load Data", { variant: "error", tag: "FailedToLoadData" });
      } finally {
        setLoading(false);
      }
    },
  }).execute;

  // Handle search state update and reset current page to 1
  const onSearch = (searchState) => {
    setActiveSearch(searchState);
    setCurrentPage(1);
  };

  // Effect to trigger search query on entityID, currentPage, or activeSearch change
  useEffect(() => {
    executeSearchQuery();
  }, [entityID, currentPage, activeSearch]);

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={12} className={clsx(classes.header)}>
        <Title className={classes.title}>Current Valet Inventory</Title>
      </Grid>

      {/* Search Component */}
      <ValetInventorySearch onSearch={onSearch} loading={loading} />

      {/* Loading and Results Container */}
      <Grid container className={clsx(classes.infoContainer)}>
        <Grid item xs={12}>
          {loading ? (
            <div className={clsx(classes.loadingWrapper)}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} className={classes.skeleton} animation="wave" />
              ))}
            </div>
          ) : (
            <>
              <Divider className={classes.resultsDivider} />
              {totalCount === 0 && (
                <div className={clsx(["animate__animated", "animate__flipInX", "results", "not-found", classes.noResults])}>
                  <span className={clsx(["no-results-text"])}>No results found</span>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/* Table and Pagination Container */}
      <Grid container item spacing={2} xs={12} className={clsx(classes.tableContainer)}>
        {!loading && totalCount > 0 && (
          <>
            <Pagination
              className={clsx(classes.pagination)}
              count={Math.ceil(totalCount / pageSize)}
              onChange={(_, value) => setCurrentPage(value)}
              page={currentPage}
              shape="rounded"
              color="primary"
            />
            <ValetInventoryTable results={results} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ValetInventory;
