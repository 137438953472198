import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { useForm, Controller } from "react-hook-form";
import { DateTimePicker } from "@material-ui/pickers";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useHasPermissions from "../../../hooks/useHasPermissions";
import moment from "moment";
import { useCountry } from "../../../hooks/useCountry";
import clsx from "clsx";
import { useStyles } from "./styles";

const validationSchema = Yup.object()
  .shape({
    validFrom: Yup.date()
      .required("Valid From is required.")
      .test("before", "Valid From must be before Valid To", function(value) {
        return !this.parent.validTo || value < this.parent.validTo;
      }),
    validTo: Yup.date()
      .required("Valid To is required.")
      .test("after", "Valid To must be after Valid From", function(value) {
        return !this.parent.validFrom || value > this.parent.validFrom;
      }),
    phone: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .matches(/^\D*(\d\D*){10}$/, "Phone number must contain exactly 10 digits."),
    email: Yup.string()
            .email("Email must be a valid email address.")
            .nullable(),
    })
  .test(
    "number-or-email",
    "You must enter either a phone number or an email address.",
    function(value) {
      const hasPhoneNumber = value.phone;
      const hasEmailAddress = value.email;
      return hasPhoneNumber || hasEmailAddress;
    }
  );

const CreateOnePassForm = ({
  onCancel,
  ticketDetails,
  onePassDetails,
  sendOnePass,
  entityID
}) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const { countries } = useCountry();

  const defaultValues = {
    roomNumber: "",
    validFrom: "",
    validTo: moment().format("MM/DD/YYYY h:mm A"),
    name: "",
    entityID,
    email: "",
    phone: "",
    accessGroupID: "",
    ticketID: "",
    overageRateId : ""
  }

  const [formData, setFormData] = useState(defaultValues);

  const getValidDate = (date) => {
    if (!date) return null;
    return moment
      .utc(date)
      .local()
      .format("MM/DD/YYYY h:mm A");
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
    trigger,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { ...formData },
  });

  const emailValue = watch("email");
  const phoneValue = watch("phone");

  useEffect(() => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        ticketID: ticketDetails?.ticketID || "",
        validFrom: getValidDate(ticketDetails?.issuedDate),
        accessGroupID: onePassDetails?.accessGroupId
      };

      reset(updatedData);
      return updatedData;
    });
  }, [ticketDetails, onePassDetails, reset]);

  const permissions = {
    showRoomField: hasPermissions(["showroomfield"]),
    showNameField: hasPermissions(["shownamefield"]),
  };

  const onSubmit = (data) => {
    if (!isValid) return;
    sendOnePass(data);
  };

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.formContainer}>
      <Grid item xs={10} sm={8} className="create-one-pass-form">
        {ticketDetails && (
          <Typography variant="h6" align="center" className="create-one-pass-ticketid">
            Ticket: {ticketDetails?.ticketID?.slice(ticketDetails.ticketID?.length - 12)}
          </Typography>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}></Grid>
            {permissions.showRoomField && (
              <Grid item xs={12}>
                <Controller
                  name="roomNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="room-input"
                      label="Room"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {permissions.showNameField && (
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="name-input"
                      label="Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="validFrom"
                control={control}
                rules={{ required: "Valid From is required." }}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    className="create-one-pass-valid-from-date-picker"
                    required
                    label="Valid From"
                    inputVariant="outlined"
                    fullWidth
                    onChange={(date) => {
                      field.onChange(date);
                      trigger("validTo");
                    }}
                    error={!!errors.validFrom}
                    helperText={errors.validFrom?.message}
                    format="MM/DD/YYYY h:mm A"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="validTo"
                control={control}
                rules={{ required: "Valid To is required." }}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    className="create-one-pass-valid-to-date-picker"
                    required
                    label="Valid To"
                    inputVariant="outlined"
                    fullWidth
                    onChange={(date) => {
                      field.onChange(date);
                      trigger("validFrom");
                    }}
                    error={!!errors.validTo}
                    helperText={errors.validTo?.message}
                    format="MM/DD/YYYY h:mm A"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    fullWidth
                    id="phone-input"
                    label="Phone"
                    variant="outlined"
                    error={!emailValue && !phoneValue ? true : !!errors.phone}
                    helperText={
                      !emailValue && !phoneValue ? "Phone or Email must be provided" : errors.phone?.message
                    }
                    defaultCountry={"us"}
                    regions={["north-america"]}
                    disableAreaCodes
                    disableCountryCode
                    onlyCountries={countries.map((country) =>
                      country.alpha2.toLowerCase()
                    )}
                    excludeCountries={["pm", "bm"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email-input"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!emailValue && !phoneValue ? true : !!errors.email}
                    helperText={
                      !emailValue && !phoneValue ? "Phone or Email must be provided" : errors.email?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                className="create-one-pass-send-button"
              >
                Send
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                className={clsx("create-one-pass-cancel-button", classes.createOnePassCancelButton)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateOnePassForm;
