import React, { useEffect, useState } from "react";
import TicketFieldPicker from "./TicketFieldPicker/TicketFieldPicker";
import { Typography, Grid, Divider, Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import TicketFieldService from "../../services/TicketFieldService";
import apiClient from "../../auth/apiClient";
import TicketFieldList from "./TicketFieldList/TicketFieldList";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import PropTypes from "prop-types";
import { ENTITY_TYPE } from "../../constants";
import { FindEntity } from "../../state/slices/entities";
import { useSelector, shallowEqual } from "react-redux";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectParentEntity} from "../../state/slices/CoreEntity";
import {selectOrgParentEntity} from "../../state/slices/OrgTree";

const ticketFieldService = new TicketFieldService(apiClient);

const TicketFields = ({ entityID, requestType, onClose }) => {

  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [availableTicketFields, setAvailableTicketFields] = useState([]);
  const [entityTicketFields, setEntityTicketFields] = useState([]);
  const requestName = ticketFieldService.getRequestName(requestType);
  const [isSaving, setIsSaving] = useState(false);
  const useCoreEntitySlice = useCoreEntityContext();

  const parentEntity = useSelector((state) => {
    if (useCoreEntitySlice) {
      const parentEntity = selectParentEntity(entityID)(state);

      if(!parentEntity.entityId) {
        return selectOrgParentEntity(entityID)(state);
      }
      return selectParentEntity(entityID)(state);
    }

    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return FindEntity(state.entities?.EntityList ?? [], entity.parententityid);
  }, shallowEqual);
  //"isEditable" variable is used to check whether Arrival Ticket fields can be editable or not.
  //(Valet Area, Facility Group Under Tenant ->Editable)
  //(Valet Area under Facilty Group -> View Only).
  const isEditable = parentEntity.typeid === ENTITY_TYPE.FacilityGroup ? false : true;

  const handleAddTicketField = async ticketfield => {
    const exists = entityTicketFields.some(x => x.fieldName == ticketfield.fieldName);

    if (exists) {
      enqueueSnackbar(
        `${requestName} Ticket Field Already Selected`, {
        variant: "warning"
      });
      return false;
    }

    if (!ticketfield.fieldID) {
      enqueueSnackbar(`Select Correct ${requestName} Ticket Field`, {
        variant: "warning"
      });
      return false;
    }

    try {
      let entityFieldObj = {};
      entityFieldObj.fieldID = ticketfield.fieldID;
      entityFieldObj.entityID = entityID;
      entityFieldObj.required = false;

      await ticketFieldService.createEntityTicketField(entityFieldObj);
      getEntityTicketFields();
      return true;

    } catch (error) {
      enqueueSnackbar(`Failed to add ${requestName} Ticket Field to entity`, {
        variant: "error"
      });
    }
    return false;
  };

  const handleRequiredField = async ticketfield => {
    try {
      let entityFieldObj = {};
      entityFieldObj.fieldID = ticketfield.fieldID;
      entityFieldObj.entityID = entityID;
      entityFieldObj.required = ticketfield.required;

      let result = await ticketFieldService.updateTicketField(entityFieldObj);
      let arr = [...entityTicketFields];
      setEntityTicketFields(arr =>
        arr.map(x => x.fieldID === ticketfield.fieldID ? { ...x.required, ...result.data.required } : x));
      setEntityTicketFields([...arr]);
      return true;

    } catch (error) {
      enqueueSnackbar(`Failed to update ${requestName} Ticket Field`, {
        variant: "error"
      });
    }
    return false;
  };

  const handleDelete = async fieldID => {
    try {
      let entityFieldObj = {};
      entityFieldObj.fieldID = fieldID;
      entityFieldObj.entityID = entityID;
      await ticketFieldService.deleteEntityTicketField(entityFieldObj);
      enqueueSnackbar(`${requestName} Ticket Field Successfully Removed`, {
        variant: "success"
      });
      getEntityTicketFields();

    } catch (err) {
      enqueueSnackbar(`Failed to remove ${requestName} Ticket Field`, { variant: "error" });
    }
  };

  const getTicketFields = async () => {
    try {
      let ticketFieldObj = {};
      ticketFieldObj.requestType = requestType;

      let result = await ticketFieldService.getTicketFields(ticketFieldObj);
      setAvailableTicketFields([...result.data]);
    } catch (error) {
      enqueueSnackbar(`Failed to get ${requestName} Ticket Fields Values`, {
        variant: "error"
      });
    }
  };

  const getEntityTicketFields = async () => {
    try {

      let entityFieldObj = {};
      entityFieldObj.requestType = requestType;
      entityFieldObj.entityID = entityID;

      let result = await ticketFieldService.getEntityTicketFields(entityFieldObj);

      setEntityTicketFields([...result.data]);

    } catch (error) {
      enqueueSnackbar(`Failed to get existing ${requestName} Ticket Fields for entity`, {
        variant: "error"
      });
    }
  };

  const onSave = async () => {
    try {

      setIsSaving(true);
      const items = Array.from(entityTicketFields);
      // Update the entityTicketFields with new order
      const updatedFields = items.map((item, index) => ({
        ...item,
        orderID: index + 1,
      }));

      setEntityTicketFields(updatedFields);

      const ticketFieldArray = updatedFields.map((record) => {
        return {
          entityID: record.entityID,
          fieldID: record.fieldID,
          orderID: record.orderID,
        };
      });
      let _payload = {};
      _payload.orderTicketFields = ticketFieldArray;
      await ticketFieldService.updateTicketFieldOrder(_payload);
    } catch (error) {
      enqueueSnackbar(`Failed to Save ${requestName} Ticket Field Order`, {
        variant: "error"
      });
    }
    onClose();
    setIsSaving(false);
  };

  const onHandleDrag = (result) => {
    if (!result.destination) return;

    const items = Array.from(entityTicketFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the entityTicketFields with new order
    const updatedFields = items.map((item, index) => ({
      ...item,
      orderID: index + 1,
    }));
    setEntityTicketFields(updatedFields);
  };

  useEffect(() => {
    getTicketFields();
  }, [entityID]);

  useEffect(() => {
    getEntityTicketFields();
  }, [availableTicketFields]);

  return (
    <div className={classes.TicketFieldsDrawer}>
      <Grid container className={clsx(`title-container`, "-ticket-fields-title", classes.container)}>
        <Grid item xs={12} mg={12} lg={12}>
          <Typography
            variant="h5"
            color="primary"
            component="h2"
            className={clsx("title")}
          >
            {requestName} Ticket Fields
          </Typography>
          <Divider></Divider>
        </Grid>
      </Grid>
      {isEditable && (
        <Grid item className={clsx("dropdown-container")}>
          <TicketFieldPicker
            handleAddButton={handleAddTicketField}
            existingTicketFields={[...availableTicketFields]}
          />
        </Grid>
      )}
      <div className={classes.ticketfieldcontainer}>
        <Grid container className={clsx("-ticket-fields-container")}>
          <Grid
            item
            className={clsx("list-container")}
          >
            <TicketFieldList
              ticketFieldArray={[...entityTicketFields]}
              entityID={entityID}
              handleDeleteButton={handleDelete}
              handleRequiredField={handleRequiredField}
              isEditable={isEditable}
              requestName={requestName}
              onHandleDrag={onHandleDrag}
            />
          </Grid>
        </Grid>
        <div className={classes.btnGroup}>
          <Box position="fixed" bottom={0} left={0} right={0} p={2}>
            <Button
              disabled={isSaving}
              color="primary"
              name="submit"
              type="submit"
              variant="contained"
              className={clsx("saveBtn", classes.btn)}
              onClick={onSave}
              data-testid="saveBtn"
              style={{ marginRight: "30px" }}
            >
              Save
            </Button>
            <Button
              className={clsx("closeBtn", classes.btn)}
              name="close"
              variant="contained"
              data-testid="closeBtn"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

TicketFields.defaultProps = {
  entityID: "",
  requestType: 1,
  onClose: () => { }
};

TicketFields.propTypes = {
  entityID: PropTypes.string,
  requestType: PropTypes.number,
  onClose: PropTypes.func
};

export default TicketFields;
