class ValetInventoryService {
	constructor(client) {
		this.apiClient = client;
	}

  async getValetInventoryData(entityID, offset, limit, cancelToken) {
    return this.apiClient.get(`valetinventory/${entityID}/${offset}/${limit}`, cancelToken);
  }	
  
  async getValetTicketDetails(transactionID, cancelToken) {
	return this.apiClient.get(`valetinventory/details/${transactionID}`, cancelToken);
  }

  async getValetTransactionHistory(transactionId, cancelToken) {
	return this.apiClient.get(`valetinventory/history/${transactionId}`, cancelToken);
  }

  async getValetInventorySearch(entityID, minEntranceTime, searchTerm, searchContext, limit, offset, cancelToken) {
	return this.apiClient.get(`valetinventory/search/${entityID}`, {
		params: {
			SearchContext: searchContext,
			MinEntranceTime: minEntranceTime,
			SearchTerm: searchTerm || undefined,
			Limit: limit ?? 10,
			Offset: offset ?? 0
		}
	}, cancelToken);
  }
}



export default ValetInventoryService;