import React, {useEffect, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {RemoveRedEye} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {useSelector} from 'react-redux';
import {FindEntity} from '../../../../state/slices/entities';
import {useForm, Controller} from "react-hook-form";
import {useSettingDispatchChange} from "../index";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useEnqueueSnackbar} from "../../../../hooks/useEnqueueSnackbar";
import {useConfirmationDialog} from "../../../../hooks/useConfirmationDialog";
import {useStyles} from "./styles";
import {useStyles as useRootStyles} from "../styles";
import apiClient from "../../../../auth/apiClient";
import TicketmasterService from '../../../../services/TicketmasterService';
import useFormHandlers from '../useFormHandlers';
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../../state/slices/CoreEntity";
import EntityTree from "../../../../pages/EntityTree";

const ticketmasterService = new TicketmasterService(apiClient);
const formSchema = Yup.object().shape({
  ticketmasterurl: Yup.string().url('Value must be a valid URL'),
  ticketmastertoken: Yup.string(),
  ticketmastervenueid: Yup.string(),
  ticketmastergatename: Yup.string(),
});

const TicketmasterSettingsForm = ({
                                    identifier,
                                    entityID,
                                    settingValues
                                  }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [styleProps, setStyleProps] = useState({display: 'none'});
  const [loading, setLoading] = useState(false)
  const [isDeviceRegisterDisabled, setIsDeviceRegisterDisabled] = useState();
  const rootClasses = useRootStyles();
  const useCoreEntitySlice = useCoreEntityContext();

  const classes = useStyles(styleProps);

  const {entityId, entityName, entityType} = useSelector((state) => {
    const entity = useCoreEntitySlice
        ? selectEntityById(state, entityID)
        : FindEntity(state.entities?.EntityList, entityID) || {};

    return {
      entityType: entity?.type || "" ,
      entityName: entity?.name || "",
      entityId: entity?.entityid || ""
    }
  });
  const {control, getValues, setValue, reset, watch} = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  });
  const {dispatchSetting} = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();
  const {textConfirmation} = useConfirmationDialog();
  const settings = {
    ticketMasterUrl: {
      name: 'ticketmasterurl',
      label: 'Ticketmaster API URL'
    },
    ticketMasterToken: {
      name: 'ticketmastertoken',
      label: 'Authentication Token'
    },
    ticketMasterVenueId: {
      name: 'ticketmastervenueid',
      label: 'Venue ID'
    },
    ticketMasterMacAddress: {
      name: 'ticketmastermacaddress',
      label: 'Mac Address',
    },
    ticketMasterDeviceId: {
      name: 'ticketmasterdeviceid',
      label: 'Ticketmaster Device ID',
    },
    ticketMasterGateName: {
      name: 'ticketmastergatename',
      label: 'Ticketmaster Gate Name',
    },
  }
  const deviceRegisterValues = watch([settings.ticketMasterUrl.name, settings.ticketMasterToken.name, settings.ticketMasterVenueId.name, settings.ticketMasterGateName.name]);

  const {
    canRenderField,
  } = useFormHandlers(setValue, settingValues, entityID, entityType);

  const onSaveSetting = async (settingName, settingValue, inValid) => {
    if (!inValid) {
      const isSettingValueSaved = await dispatchSetting(entityID, settingName, settingValue)

      if (!isSettingValueSaved) {
        enqueueSnackbar("Unable to save Ticketmaster setting value", {
          variant: "error",
          tag: "ticketmasterSettingSave",
        });
      }
    }
  }

  const onExpandCollapse = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }

  const onRegisterDevice = async () => {
    try {
      let confirmed;

      setLoading(true);

      if (getValues('ticketmastermacaddress') !== '' && getValues('ticketmasterdeviceid') !== '') {
        confirmed = await textConfirmation({
          title: 'Register Device',
          message: 'This has already been registered with Ticketmaster. Re-registering will require additional configuration on the Ticketmaster software. Are you sure you want to do this?',
          textConfirmation: entityName
        });
      }

      if (confirmed === undefined || confirmed === true) {
        const deviceRegistration = {
          entityId: entityId,
          entityName: entityName
        }
        const ticketmasterResponse = await ticketmasterService.createDeviceRegistration(deviceRegistration);
        const ticketmasterDeviceInfo = ticketmasterResponse.data;

        setValue(settings.ticketMasterMacAddress.name, ticketmasterDeviceInfo.macAddress);
        setValue(settings.ticketMasterDeviceId.name, ticketmasterDeviceInfo.deviceID);
        onSaveSetting(settings.ticketMasterMacAddress.name, ticketmasterDeviceInfo.macAddress, false);
        onSaveSetting(settings.ticketMasterDeviceId.name, ticketmasterDeviceInfo.deviceID, false);
      }
    } catch (error) {
      let errorMessage;

      if (error.data) {
        if (error.status === 401 || error.status === 404) {
          errorMessage = 'Unable to Register - Please check Authentication Token and Venue ID values are correct'
        } else {
          errorMessage = error.data
        }
      } else {
        errorMessage = error.message
      }

      enqueueSnackbar(errorMessage, {
        variant: "error",
        tag: "ticketmasterDeviceRegister",
      });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (settingValues) {
      const entitySettings = Object.entries(settingValues);

      if (entitySettings.length) {
        let formValues = {};

        Object.entries(settings).forEach(([key, value]) => {
          const entitySetting = entitySettings.find((entitySetting) => entitySetting[0] === value.name);

          if (entitySetting) {
            formValues[entitySetting[0]] = entitySetting[1]
          }
        });

        reset(formValues);
      }
    }
  }, [])

  useEffect(() => {
    if (loading) {
      setStyleProps({display: 'block'});
    }
  }, [loading])

  useEffect(() => {
    if (deviceRegisterValues) {
      setIsDeviceRegisterDisabled(deviceRegisterValues.includes(''));
    }
  }, [deviceRegisterValues])

  const [authTokenMasked, setAuthTokenMasked] = useState(false);
  const toggleAuthToken = () => { setAuthTokenMasked((currentToggle) => !currentToggle); };

  const renderUrl = canRenderField(settings.ticketMasterUrl.name);

  return (
    <Grid
      className={rootClasses.panelRoot}
      data-testid={identifier}
      container
    >
      <Accordion
        className={rootClasses.fullWidthAccordion}
        expanded={isAccordionExpanded}
        onChange={onExpandCollapse}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          data-testid='ticketmaster-settings-accordion'
        >
          <Typography className={rootClasses.heading}>
            Ticketmaster
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            alignItems='center'
            container
            direction='row'
            item
            spacing={3}
          >
            {renderUrl &&
              <>
                <Grid container direction='column' item md={6} sm={12}>
                  <Controller
                    control={control}
                    name={settings.ticketMasterUrl.name}
                    render={({field, fieldState}) => (
                      <TextField
                        label={settings.ticketMasterUrl.label}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={() => {
                          onSaveSetting(field.name, field.value, fieldState.invalid)
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        FormHelperTextProps={{
                          'data-testid': `${field.name}-helper-text`
                        }}
                        variant='outlined'
                        inputProps={{
                          'data-testid': `${field.name}-input`
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            }
            {canRenderField(settings.ticketMasterToken.name) &&
              <>
                <Grid container direction='column' item md={6} sm={12}>
                  <Controller
                    control={control}
                    name={settings.ticketMasterToken.name}
                    render={({field, fieldState}) => (
                      <TextField
                        type={authTokenMasked === true ? "text" : "password"}
                        label={settings.ticketMasterToken.label}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={() => {
                          onSaveSetting(field.name, field.value, fieldState.invalid)
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        FormHelperTextProps={{
                          'data-testid': `${field.name}-helper-text`
                        }}
                        variant='outlined'
                        inputProps={{
                          'data-testid': `${field.name}-input`
                        }}
                        InputProps={{
                          endAdornment: (
                            <RemoveRedEye
                              className={classes.eye}
                              onClick={toggleAuthToken}
                              data-testid='unmask-token-icon'
                            />
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            }
            {canRenderField(settings.ticketMasterVenueId.name) &&
              <>
                <Grid container direction='column' item md={6} sm={12}>
                  <Controller
                    control={control}
                    name={settings.ticketMasterVenueId.name}
                    render={({field, fieldState}) => (
                      <TextField
                        label={settings.ticketMasterVenueId.label}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={() => {
                          onSaveSetting(field.name, field.value, fieldState.invalid)
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        FormHelperTextProps={{
                          'data-testid': `${field.name}-helper-text`
                        }}
                        variant='outlined'
                        inputProps={{
                          'data-testid': `${field.name}-input`
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            }
            <Grid container direction='column' item md={6} sm={12}>
              <Controller
                control={control}
                name={settings.ticketMasterGateName.name}
                render={({field, fieldState}) => (
                  <TextField
                    label={settings.ticketMasterGateName.label}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={() => {
                      onSaveSetting(field.name, field.value, fieldState.invalid)
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    FormHelperTextProps={{
                      'data-testid': `${field.name}-helper-text`
                    }}
                    variant='outlined'
                    inputProps={{
                      'data-testid': `${field.name}-input`
                    }}
                  />
                )}
              />
            </Grid>
            {!renderUrl && <Grid container direction="column" item md={6} xs={0}></Grid>}
            <Grid container direction='column' item md={3} sm={6} xs={12}>
              <Button
                variant='contained'
                onClick={onRegisterDevice}
                disabled={loading || isDeviceRegisterDisabled}
                data-testid='device-registration-button'
              >
                {loading &&
                  <div className={classes.overlay}>
                    <div className={classes.overlayContent}>
                      <CircularProgress
                        color='inherit'
                        size={20}
                      />
                    </div>
                  </div>
                }
                Register Device
              </Button>
            </Grid>
            <Grid container direction='column' item md={9} sm={6} xs={12}>
              <Controller
                control={control}
                name={settings.ticketMasterMacAddress.name}
                render={({field}) => (
                  <Typography className={classes.disabledLabel} variant='body1' data-testid={`${field.name}-text`}>
                    {field.value !== '' ? field.value : 'Not Registered'}
                  </Typography>
                )}
              />
            </Grid>
            <Grid container direction='column' item md={3} sm={6} xs={12}>
              <Typography className={classes.disabledLabel} variant='body1'>
                {settings.ticketMasterDeviceId?.label}
              </Typography>
            </Grid>
            <Grid container direction='column' item md={9} sm={6} xs={12}>
              <Controller
                control={control}
                name={settings.ticketMasterDeviceId.name}
                render={({field}) => (
                  <Typography className={classes.disabledLabel} variant='body1' data-testid={`${field.name}-text`}>
                    {field.value !== '' ? field.value : 'Not Registered'}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TicketmasterSettingsForm;
