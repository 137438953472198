import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import apiClient from "../../../auth/apiClient";
import {
  Dialog,
  DialogContent
} from "@material-ui/core";
import { isUndefined } from "lodash";
import clsx from "clsx";
import _ from "lodash";
import ListView from "./AccesgroupsList/ListView";
import AccessGroupService from "../../../services/AccessGroupService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { generateUUID } from "../../Rate";
import { abbreviateDays } from "../../Panels/AccessGroups/utilities";
import AccessGroupForm from "../../Forms/AccessGroup";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import {
  onFiltersChanged,
  onIsDayUseAssigned,
} from "../../../reducers/accessGroups/accessGroupReducer";
import { useAccessGroupData } from "../../../providers/AccessGroupsProvider";

const accessGroupService = new AccessGroupService(apiClient);

/**
 * @param  {string} accessHolderID The AccessHolderID
 * @param  {string} searchTerm The group search criteria
 * @param  {string} className CSS class
 * @param  {boolean} selectable will render the select button if true
 * @param  {(a:accessGroup)=>void} onSelect Handles select
 * @param  {boolean} newData a change in this value triggers re-fetch of groups
 * @param  {string} mode Either: "manage", "assign", or "disabled"
 * @param  {boolean} canEdit determines whether or not to display Edit button in AccessGroupsList
 */
const AccessGroupList = ({
  accessHolderID,
  searchTerm,
  selectable,
  onSelect,
  newData,
  mode,
  groupTypeFilter,
  canEdit
}) => {
  const ITEM_LIMIT = 15;
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [accessGroups, setAccessGroups] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [formState, setFormState] = useState({ open: false });
  const { hasPermissions } = useHasPermissions();
  const accessGroupEdit = hasPermissions(["accessgroups.edit"]);
  const isSharedAccountsEnabled = useFeatureFlag("Shared Accounts");
  const isCVPSEnabled = useFeatureFlag("CVPS Dual Pairing");
  const isFlexibleParkingAccountEnabed = useFeatureFlag("Flexible Parking Account");
  const isDayUseEnabled = useFeatureFlag("Day Use");
  const [totalCount, setTotalCount] = React.useState(0);
  const { accessGroupData } = useAccessGroupData();
  const [values, dispatch] = accessGroupData;
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const AccessGroupAssignPermission = hasPermissions(
    ["accessholders.add", "accessholders.edit"],
    true
  );
  const [isFiltersSearchData, setisFiltersSearchData] = useState(0);
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: onFiltersChanged,
      payload: { accessGroupPage: newPage }
    });
  };

  useEffect(() => {
    if (!isUndefined(accessHolderID) && mode === "assign")
      fetchAssignedAccessGroups(accessHolderID, searchTerm).then();
    else if (mode === "manage") {
      fetchAccessGroups(searchTerm).then();
    }
  }, [accessHolderID, searchTerm, scopeAwareFacilityID, newData, groupTypeFilter, values?.accessGroupPage]);

  const fetchAccessGroups = useCallback(
    // ( Presenting the user with groups to associate with its access holder )
    async searchTerm => {
      let allGroupsResponse, myGroupsResponse;
      if (searchTerm !== prevSearchTerm) {
        setPrevSearchTerm(searchTerm);
        dispatch({
          type: onFiltersChanged,
          payload: { accessGroupPage: 1 }
        }); 
      }
      try {
        allGroupsResponse = await accessGroupService.GetAccessGroups(
          scopeAwareFacilityID,
          accessHolderID ? 100 : ITEM_LIMIT,
          accessHolderID || searchTerm ? (!accessHolderID && searchTerm ? getSearchValue() : 0)
            : values?.accessGroupPage ? (values.accessGroupPage - 1) * ITEM_LIMIT : 0,
          searchTerm,
          groupTypeFilter
        );

        setisFiltersSearchData(searchTerm ? Math.ceil(allGroupsResponse?.data?.totalCount / ITEM_LIMIT) : 0);

        if (accessHolderID != undefined) {
          myGroupsResponse = await accessGroupService.GetAssignedAccessGroups(
            accessHolderID,
            100,
            0,
          );
          let assignedGroups = myGroupsResponse?.data.collection ?? [];
          let unassignedGroups = allGroupsResponse?.data.collection.filter(
            a =>
              !assignedGroups.some(
                item => item.name === a.name && item.facilityID === a.facilityID
              )
          );
          setAccessGroupWithFeatureFlag(unassignedGroups);
          setTotalCount(allGroupsResponse?.data?.totalCount || 0);
        } else {
          setAccessGroupWithFeatureFlag(allGroupsResponse?.data.collection);
          setTotalCount(allGroupsResponse?.data?.totalCount || 0);
        }
      } catch (ex) {
        enqueueSnackbar("Failed to retrieve access groups", {
          variant: "error",
          tag: "fetchGroupsError"
        });
      }
      return;
    },
    [accessGroupService.GetAccessGroups, scopeAwareFacilityID, searchTerm, groupTypeFilter, values?.accessGroupPage]
  );

  const getSearchValue = () => {
    return isFiltersSearchData >= values.accessGroupPage ? ((values.accessGroupPage - 1) * ITEM_LIMIT) : 0;
  };

  const fetchAssignedAccessGroups = useCallback(
    async (accessHolderID, searchTerm) => {
      let response;
      try {
        response = await accessGroupService.GetAssignedAccessGroups(
          accessHolderID,
          100,
          0,
          searchTerm
        );
      } catch {
        enqueueSnackbar("Failed to retrieve assigned access groups", {
          variant: "error",
          tag: "fetchAssignedGroupsError"
        });
      }

      dispatch({
        type: onIsDayUseAssigned,
        payload: { isDayUseAvailable: Boolean(response?.data.collection.find(group => group.type === 'Day Use')) }
      });

      setAccessGroupWithFeatureFlag(response?.data.collection);
      setTotalCount(response?.data?.totalCount || 0);
    },
    [accessGroupService.GetAssignedAccessGroups, accessHolderID, searchTerm, values?.accessGroupPage]
  );

  const handleEditClick = accessGroup => {
    const selectedGroup = accessGroups.find(group => group.accessGroupID === accessGroup.id)
    setFormState({ open: true, accessGroup: selectedGroup });
  };

  const handleEditSubmit = () => {
    setFormState({ open: false });
    if (!isUndefined(accessHolderID))
      fetchAssignedAccessGroups(accessHolderID, searchTerm);
    else fetchAccessGroups(searchTerm);
  };

  const handleAccessGroupDelete = groupID => {
    let tmpGroups = accessGroups?.slice() ?? [];
    const foundIndex = tmpGroups.findIndex(x => x.accessGroupID === groupID);
    if (foundIndex < 0) return;

    tmpGroups.splice(foundIndex, 1);
    setAccessGroupWithFeatureFlag(tmpGroups);
    setFormState({ open: false });
  };

  const setAccessGroupWithFeatureFlag = (accessgroups) => {
    setAccessGroups(accessgroups?.filter(x => checkFeatureFlag(x.type)));
  }

  const checkFeatureFlag = type => {
    switch (type) {
      case "Shared Accounts":
        return isSharedAccountsEnabled;
      case "Valet Pairing":
        return isCVPSEnabled;
      case "Flexible Parking Account":
        return isFlexibleParkingAccountEnabed;
      case "Day Use":
        return isDayUseEnabled;  
      default:
        return true;
    }
  }
  const formatAccessGroups = (groups) => {
    if (!groups) return [];
    return groups
      .map((group) => {
        return {
          id: group.accessGroupID,
          name: group.name,
          type: group.type,
          'admittance Times': getFormattedTimeRange(group.admittanceRules)
        };
      });
  };

  const getFormattedTimeRange = rules => {
    if (
      !rules ||
      rules.length === 0 ||
      !rules[0].admittanceTimes ||
      rules[0].admittanceTimes == null ||
      rules[0].admittanceTimes.length === 0
    ) {
      return "24/7";
    } else {
      const identifier = generateUUID();
      let tmpRules = rules?.slice() ?? [];

      tmpRules = tmpRules.map(rule => {
        const tmpTimes = rule.admittanceTimes?.slice() ?? [];
        if (_.isEmpty(tmpTimes)) return {};
        const startTime = tmpTimes[0].startTime;
        const endTime = tmpTimes[0].endTime;
        const days = abbreviateDays(
          tmpTimes
            .flat()
            .sort(x => x.applicableDay)
            .map(x => x.applicableDay)
        );
        return { days, times: { startTime, endTime } };
      });

      return { isTimeRestrictedAccess: true, identifier: identifier, tmpRules };
    }
  };

  const handleUnassign = async groupID => {
    try {
      await accessGroupService.UnassignAccessHolderFromGroup(
        groupID.id,
        accessHolderID
      );
      enqueueSnackbar("Successfully unassigned", { variant: "success" });

      fetchAssignedAccessGroups(accessHolderID, searchTerm);
    } catch {
      enqueueSnackbar("Failed to unassign access group", {
        variant: "error",
        tag: "unassignGroupError"
      });
      return;
    }
  };

  return (
    accessGroups?.length > 0 && (
      <><ListView
        handleEditButton={handleEditClick}
        deleteButtonToggle={mode === "assign" && AccessGroupAssignPermission ? true : false}
        handleDeleteButton={handleUnassign}
        editButtonToggle={accessGroupEdit && canEdit}
        titleBarTitleText="Access Groups"
        titleBarAddButtonToggle={false}
        data={formatAccessGroups(accessGroups)}
        handlePageChange={handleChangePage}
        currentPage={values?.accessGroupPage}
        rowsToShowPerPage={[15, 25, 50]}
        rowsToShowDefault={mode === "assign" || selectable === true ? 100 : 15}
        paginationToggle={mode === "assign" || selectable === true ? false : true}
        sortCaseInsensitive={true}
        titleBarTextToggle={true}
        showTableHead={true}
        totalCount={Math.ceil(totalCount / ITEM_LIMIT)}
        queryPagination={true}
        additionalButtons={selectable === true && AccessGroupAssignPermission ? [
          {
            title: "Select",
            handleClick: id => {
              onSelect(id)
            }
          }
        ] : []}
        titleBarToggle={mode === "assign" ? false : true}
      />
        <Dialog
          className={clsx("accessgroup-dialog")}
          role="accessgroup-dialog"
          fullWidth
          maxWidth="md"
          open={formState.open}
        >
          <DialogContent className={clsx("accessgroup-dialog-content")}>
            <AccessGroupForm
              className={clsx("accessgroup-form")}
              data={formState.accessGroup}
              onCancel={() => setFormState({ open: false })}
              onSubmit={handleEditSubmit}
              onDelete={handleAccessGroupDelete}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

AccessGroupList.defaultProps = {
  selectable: false,
  onSelect: () => { },
  onEditClick: () => { },
  mode: "manage",
  groupTypeFilter: "",
  canEdit: false
};

AccessGroupList.propTypes = {
  accessHolderID: PropTypes.string,
  searchTerm: PropTypes.string,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  mode: PropTypes.oneOf(["assign", "manage", "disabled"]),
  groupTypeFilter: PropTypes.string,
  canEdit: PropTypes.bool
};

export default AccessGroupList;
