import React, { useCallback, useEffect } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { AppBar, Container, Tab, Tabs } from "@material-ui/core";
import EntityDetailSettings from "./Settings";
import EntityDetailActivity from "./Activity";
import EntityDetailDetails from "./Details";
import TimelineIcon from "@material-ui/icons/Timeline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TuneIcon from "@material-ui/icons/Tune";
import SettingsIcon from "@material-ui/icons/Settings";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { shallowEqual, useSelector } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";
import * as c from "../../../constants";
import clsx from "clsx";
import EntityDetailFeatures from "./Features";
import { isUndefined } from "lodash";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {selectOrgTreeEntityById} from "../../../state/slices/OrgTree";


const setDefaultTab = (entityType) => {
  switch (entityType) {
    case c.ENTITY_TYPE.FacilityGroup:
    case c.ENTITY_TYPE.ValetArea:
      return "tabSettings";
    case c.ENTITY_TYPE.Area:
    case c.ENTITY_TYPE.Facility:
      return "tabActivity";
    default:
      return "tabDetails";
  }
}
export const EntityExpandedView = ({ entityId }) => {
  const useCoreEntitySlice = useCoreEntityContext();

  const TAB_TYPE = {
    Details: 1,
    Activity: 2,
    Features: 3,
    Settings: 4,
  };
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const entity = useSelector(
    (state) => {
      let entity = useCoreEntitySlice ? selectEntityById(state,entityId) : FindEntity(state.entities?.EntityList ?? [], entityId);
      if(useCoreEntitySlice && !entity?.entityid){
        entity = selectOrgTreeEntityById(state, entityId);
      }

      return {
        typeid: entity?.typeid,
        id: entity?.entityid,
        devicemode: entity?.settings?.find(
          (setting) => setting.name === "devicemode"
        ),
      };
    }, shallowEqual);

  const [selectedDetailsTab, setSelectedDetailsTabValue] = React.useState(setDefaultTab(entity?.typeid || c.ENTITY_TYPE.Device));

  const isHotelDevice =
    entity?.devicemode?.value.toLowerCase() ===
    c.DEVICE_MODE.HOTEL_INTERFACE.toLowerCase();

  const canEditSettings = () => {
    switch (entity.typeid) {
      case c.ENTITY_TYPE.FacilityGroup:
        // Might need to replace with permissions for FG in later phase
        // Currently no permissions for FG.
        return hasPermissions(
          [
            "overview.facilities.editsettings",
            "overview.facilities.editdemographics",
          ],
          true,
          entity.id
        );

      case c.ENTITY_TYPE.Facility:
        return hasPermissions(
          [
            "overview.facilities.editsettings",
            "overview.facilities.editdemographics",
          ],
          true,
          entity.id
        );

      case c.ENTITY_TYPE.Device:
        return hasPermissions(
          [
            "overview.devices.editsettings",
            "overview.devices.editdemographics",
          ],
          true,
          entity.id
        );
      case c.ENTITY_TYPE.ValetArea:
        return hasPermissions(
          [
            "overview.valetarea.editsettings",
            "overview.valetarea.editdemographics",
          ],
          true,
          entity.id
        );
      case c.ENTITY_TYPE.Area:
        return hasPermissions(
          [
            "overview.area.editsettings",
            "overview.area.addarea",
          ],
          true,
          entity.id
        );
    }
  };

  const canRenderTab = (tabType) => {
    const entityType = entity?.typeid;
    if (isUndefined(entityType)) {
      return false;
    }

    switch (tabType) {
      case TAB_TYPE.Details:
        return (entityType !== c.ENTITY_TYPE.Facility
          && entityType !== c.ENTITY_TYPE.FacilityGroup
          && entityType !== c.ENTITY_TYPE.Area
          && entityType !== c.ENTITY_TYPE.ValetArea);
      case TAB_TYPE.Activity:
        return (entityType !== c.ENTITY_TYPE.FacilityGroup
          && entityType !== c.ENTITY_TYPE.ValetArea
          && !isHotelDevice);
      case TAB_TYPE.Features:
        return (entityType !== c.ENTITY_TYPE.FacilityGroup
          && entityType !== c.ENTITY_TYPE.Device
          && entityType !== c.ENTITY_TYPE.Area);
      case TAB_TYPE.Settings:
        return canEditSettings();
    }
  };

  useEffect(() => {
    setSelectedDetailsTabValue(setDefaultTab(entity?.typeid || c.ENTITY_TYPE.Device) );
  }, [entity?.typeid]);

  const handleDetailsTabChange = (event, value) => {
    setSelectedDetailsTabValue(value);
  };

  const TabPanel = useCallback((props) => {
    const { children, value } = props;

    if (selectedDetailsTab !== value) {
      return <></>
    }

    return (
      <div className={classes.tabPanel}>
        {children}
      </div>
    );
  }, [selectedDetailsTab]);

  return (
    <Container>
      <AppBar position="fixed">
        <Tabs
          centered
          variant="fullWidth"
          value={selectedDetailsTab}
          aria-label="wrapped label tabs example"
          onChange={handleDetailsTabChange}
        >
          {canRenderTab(TAB_TYPE.Details) && (
            <Tab
              className={clsx(["tab-details"])}
              value="tabDetails"
              icon={<InfoOutlinedIcon />}
              label="Details"
            ></Tab>
          )}
          {canRenderTab(TAB_TYPE.Activity) && (
            <Tab
              className={clsx(["tab-activity"])}
              value="tabActivity"
              icon={<TimelineIcon />}
              label="Activity"
            ></Tab>
          )}
          {canRenderTab(TAB_TYPE.Features) && (
            <Tab
              className={clsx(["tab-features"])}
              value="tabFeatures"
              icon={<TuneIcon />}
              label="Features"
            ></Tab>
          )}
          {canRenderTab(TAB_TYPE.Settings) && (
            <Tab
              className={clsx(["tab-settings"])}
              value="tabSettings"
              icon={<SettingsIcon />}
              label="Settings"
            ></Tab>
          )}
        </Tabs>
      </AppBar>
      <div
        style={{
          marginTop: 55,
        }}
      ></div>
      {canRenderTab(TAB_TYPE.Details) && (
        <TabPanel value="tabDetails" index={0}>
          <EntityDetailDetails entityId={entity.id} />
        </TabPanel>
      )}
      {canRenderTab(TAB_TYPE.Activity) && (
        <TabPanel value="tabActivity" index={1}>
          <EntityDetailActivity entityId={entity.id} />
        </TabPanel>
      )}
      {canRenderTab(TAB_TYPE.Features) && (
        <TabPanel value="tabFeatures" index={2}>
          <EntityDetailFeatures
            entityId={entity.id}
            entityTypeId={entity.typeid}
          />
        </TabPanel>
      )}
      {canRenderTab(TAB_TYPE.Settings) && (
        <TabPanel value="tabSettings" index={3}>
          <EntityDetailSettings entityId={entity.id} />
        </TabPanel>
      )}
    </Container>
  );
};

EntityExpandedView.defaultProps = {
  entityId: undefined,
  entityData: undefined,
  editData: undefined,
};
EntityExpandedView.propTypes = {
  entityId: PropTypes.string,
  entityData: PropTypes.object,
  editData: PropTypes.object,
};

export default EntityExpandedView;
