import React, { useState } from "react";
import clsx from "clsx";
import moment from "moment-timezone";
import { useStyles } from "./ValetInventoryTable.styles";
import "../../ResponsiveTable/_responsive.table.css";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";
import ValetInventoryDetailDrawer from '../ValetInventoryDetailDrawer/ValetInventoryDetailDrawer'

const ValetInventoryTable = ({ results = [] }) => {
  const classes = useStyles();
  const { timeZone } = useCurrentFacilityTimezone();
  const [selectedCredential, setSelectedCredential] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onRowClick = (result) => {
    setSelectedCredential(result);
    setDrawerOpen(true);
  }

  const onDrawerClose = () => {
    setSelectedCredential(null);
    setDrawerOpen(false)
  }

  return (
    <>
    <table className={clsx(classes.resultTable, "a1-responsive")}>
      <thead className={clsx(classes.thHeader)}>
        <tr>
          <th className={clsx([classes.resultHeader])}>Ticket Number</th>
          <th className={clsx([classes.resultHeader])}>Customer</th>
          <th className={clsx([classes.resultHeader])}>Guest Type</th>
          <th className={clsx([classes.resultHeader])}>Rate</th>
          <th className={clsx([classes.resultHeader])}>Entrance Time</th>
          <th className={clsx([classes.resultHeader])}>Make</th>
          <th className={clsx([classes.resultHeader])}>Model</th>
        </tr>
      </thead>
      
      <tbody>
      {results.map((result, index) => (
              <tr key={result.credentialId} 
                className={clsx(['clickable-row'])} 
                onClick={() => onRowClick(result)} data-selected={result.contextID === selectedCredential?.contextID} 
                data-testid="clickable-row">
              <td data-column="Ticket Number" className={clsx([classes.column])}>
                {result.ticketNumber}
              </td>
              <td data-column="Customer" className={clsx([classes.column])}>
                {[result.firstName, result.lastName].filter(Boolean).join(" ")}
              </td>
              <td data-column="Guest Type" className={clsx([classes.column])}>
                {result.guestType}
              </td>
              <td data-column="Rate" className={clsx([classes.column])}>
                {result.rate}
              </td>
              <td data-column="Entrance Time" className={clsx([classes.column])}>
                <span>{moment.utc(result.entranceTime).tz(timeZone).format("MM/DD/YYYY h:mm A")}</span>
              </td>
              <td data-column="Make" className={clsx([classes.column])}>
                {result.make}
              </td>
              <td data-column="Model" className={clsx([classes.column])}>
                {result.model}
              </td>
            </tr>
          ))}
        </tbody>
    </table>
          <ValetInventoryDetailDrawer credential={selectedCredential} open={drawerOpen} onClose={(success) => onDrawerClose()} />
      </>
  );
};

export default ValetInventoryTable;
