class ValidationUserOnePassService {
  constructor(client) {
    this.apiClient = client;
  }

  async getValidationUserOnePassDetails(userId) {
    return await this.apiClient.get(`onepass-details`, {
      params: {
        userId: userId
      },
    });
  }
}

export default ValidationUserOnePassService;