import React from "react";
import PropTypes from "prop-types";
import Activity from "../../../Activity";
import { useSelector, shallowEqual } from "react-redux";
import { FindEntity, FindNearestFacilityFromEntity } from "../../../../state/slices/entities";
import AccessGroupProvider from "../../../../providers/AccessGroupsProvider";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectEntityById,findNearestFacilityFromEntity as coreFindNearestFacilityFromEntity} from "../../../../state/slices/CoreEntity";
import {selectOrgTreeEntityById} from "../../../../state/slices/OrgTree";

// TODO: JonathanPayares need to refactor for orgSlice Changes keeping the same until that story
export const EntityDetailActivity = ({ entityId }) => {
  const useCoreEntitySlice = useCoreEntityContext();

  // TODO: for now, this component can provide correct timezone ONLY for Facility and Device levels (For Tenant, Need to determine which timezone app will need to use).
  const timezone = useSelector((state) => {
    let value;
    let currentEntity = useCoreEntitySlice ? selectEntityById(state,entityId) : FindEntity(state.entities?.EntityList ?? [], entityId);

    if(useCoreEntitySlice && !currentEntity) {
      currentEntity = selectOrgTreeEntityById(state,entityId)
    }

    switch (currentEntity?.type?.toLowerCase()) {
      case "facility":
        value = currentEntity?.details?.timezone;
        break;
      case "device":

        value = useCoreEntitySlice? coreFindNearestFacilityFromEntity(state.coreEntities,entityId)?.details?.timezone : FindNearestFacilityFromEntity(
            state.entities?.EntityList ?? [],
            currentEntity?.entityid
        )?.details?.timezone;

        if (useCoreEntitySlice){
          value = coreFindNearestFacilityFromEntity(state.coreEntities.entities,entityId)?.details?.timezone
                ||
                  coreFindNearestFacilityFromEntity(state.orgTree.entities,entityId)?.details?.timezone
        }
        break;
      default:
        value = "";
    }
    return value;
  }, shallowEqual);
  return (
    <>
    <AccessGroupProvider>
      <Activity
        hideTitle={true}
        entityId={entityId}
        tz={timezone}
        supportMultiEntities={false}
      />
      </AccessGroupProvider>
    </>
  );
};

EntityDetailActivity.defaultProps = { entityId: undefined };
EntityDetailActivity.propTypes = { entityId: PropTypes.string };

export default EntityDetailActivity;
