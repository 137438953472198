import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouseFull as ScopeIcon, faCarBuilding as FacilityIcon } from "@fortawesome/pro-duotone-svg-icons";
import { useStyles } from "./index.style";
import _ from "lodash";
import clsx from "clsx";
import { Button, Divider, ListItemIcon, Menu, MenuItem, Typography, IconButton, Badge} from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import { setSelected } from "../../state/slices/entityScope";
import { DEFAULT_SCOPE_AWARE_IDS } from "../../constants";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectContextEntityName} from "../../state/slices/CoreEntity";


const ScopeSelection = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempScopeList, setScopeList] = useState([]);
  const [selectedAll, setSelectAll] = useState(false)
  const dispatch = useDispatch();
  const facilityList = useSelector(
    (state) => {
      return _.orderBy(state.entityScope?.available, [ "name", "asc" ]);
    }, shallowEqual) ?? [];
  const scopeList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
    ) ?? [];
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const currentFacilityID = useSelector((state) =>useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID, shallowEqual);
  const currentFacilityName = useSelector((state) =>useCoreEntitySlice ? selectContextEntityName(state) : state.entities?.Context?.name, shallowEqual);
  const { "facilityGroups" : facilityGroupLDFlag } = useFlags();

  useEffect(() => {
    setScopeList(scopeList);
  }, [scopeList]);

  // Track last processed state of facilityList
  const hasProcessed = useRef(false);
  const sessionScopeAwareIds = JSON.parse(window.sessionStorage.getItem("DEFAULT_SCOPE_AWARE_IDS")) ?? [];

  useEffect(() => {

    if (facilityList.length === 1) {
      return; // Wait for facilityList to be populated
    }

    if (hasProcessed.current) {
      return; // Skip if already processed
    }

    // Filter sessionScopeAwareIds against facilityList
    const ScopeAwareIds = sessionScopeAwareIds.filter((x) =>
        facilityList.some((y) => y.id === x.id)
    );

    // Dispatch the updated selected scope
    if(ScopeAwareIds.length > 1) {
      dispatch(setSelected(ScopeAwareIds));

      window.sessionStorage.setItem("DEFAULT_SCOPE_AWARE_IDS", JSON.stringify(ScopeAwareIds));
    }

    // Mark as processed
    hasProcessed.current = true;
  }, [facilityList, dispatch]);

  useEffect(() => {
    let unselect =  facilityList.filter(data => !tempScopeList.some(temp => data.id === temp.id));
    unselect.length === 0 ? setSelectAll(true) : setSelectAll(false);
  }, [facilityList, tempScopeList]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = () => {
    let tempList = [];

    if (selectedAll) {
      tempList.push({ id: currentFacilityID, name: currentFacilityName });

    } else {
      facilityList.map(facility => tempList.push({ id: facility.id, name: facility.name }));
    }

    setScopeList(tempList);
  };

  const updateSelectedFacility = (facility) => {
    // Prevent to unselect current facility
    if (facility.id === currentFacilityID) return;

    let tempList = tempScopeList.filter((data) => data.id !== facility.id);
    tempScopeList?.find((data) => data.id === facility.id) ?? tempList.push({ id: facility.id, name: facility.name });

    setScopeList(tempList);
  }

  const handleApply = () => {
    dispatch(setSelected(tempScopeList));
    window.sessionStorage.setItem(
      DEFAULT_SCOPE_AWARE_IDS,
      JSON.stringify(tempScopeList)
    );
    setAnchorEl(null);
  };
  
  const handleClose = () => {
    setScopeList(scopeList);
    setAnchorEl(null);
  };

  return (    
    <>
      {(facilityGroupID && facilityGroupLDFlag) && (
        <div>
          <IconButton
            color="inherit"
            onClick={handleClick}
            className="facility-group-selector"
            data-testid="facility-group-selector"
          >
            <Badge
                  badgeContent={scopeList.length}
                  invisible={scopeList.length === facilityList.length}
                  classes= {{ badge: classes.customBadge }} 
            >
              <FontAwesomeIcon icon={ScopeIcon} />
            </Badge>
          </IconButton>
          <Menu
            className={clsx(classes.menu,[classes.root,"scope-menu"])}
            data-testid="scope-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{style: {minWidth: 200}}}
            anchorOrigin={{vertical: 'top',horizontal: 'left',}}
            transformOrigin={{vertical: 'top',horizontal: 'left',}}        
          >
            <Typography className={classes.title} component={'div'}>
              <ListItemIcon className={classes.titleIcon}>
                  <FontAwesomeIcon className={clsx([classes.menuTitleIcon])} icon={ScopeIcon} />
                </ListItemIcon>          
                <span className={classes.titleText}>Scope Selection</span>
            </Typography>
            <Divider />
            <div className={clsx([classes.listContainer])}>
              {facilityList.map((x,i) => 
                <MenuItem 
                  className={clsx([
                    classes.facilityListItem, 
                    "facility-item", 
                    "scope", 
                    ((_.find(tempScopeList, {id:x.id}) !== undefined) ? "selected" : "")])}              
                  onClick={() => updateSelectedFacility(x)}
                  key={i}
                  data-testid={x.name}
                  data-name={x.name}
                  selected={_.find(tempScopeList, {id:x.id}) !== undefined}              
                >
                  <ListItemIcon style={{ marginRight: -25 }}>
                    <FontAwesomeIcon 
                      className={clsx([
                        classes.facilityListItemIcon,
                        ((_.find(tempScopeList, {id:x.id}) !== undefined) ? "selected" : "")
                      ])}           
                      icon={FacilityIcon} 
                    />
                  </ListItemIcon>
                  <Typography component={'div'}>{x.name}</Typography>
                </MenuItem>
              )}        
            </div>
            <Divider />
            <div>             
              <Button onClick={handleClose} variant="outlined" size="small" color="secondary" className={clsx([classes.scopeButton,"scope button close"])}>CANCEL</Button>        
              <Button onClick={handleApply} variant="outlined" size="small" color="primary" className={clsx([classes.scopeButton,"scope button apply"])}>APPLY</Button>
              <Button onClick={handleSelect} variant="outlined" size="small" className={clsx([classes.scopeButton,"scope button select"])}>{selectedAll ? "Deselect All" : "Select All"}</Button>
            </div>
          </Menu>
        </div>
      )}
    </>
  );
};

export default ScopeSelection;