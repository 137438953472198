import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import apiClient from "../../../auth/apiClient";
import ListView from "../../ListView/ListView";
import { prettifyDuration } from "../../Rate/Inputs/DurationEntry";
import { usePayOnEntryData } from "../../../providers/PayOnEntryProvider";
import { onFiltersChanged } from "../../../reducers/payonentry/payonentryReducer";
import RateService from "../../../services/RateService";
import { useStyles } from "./styles";
import usePoeState from "../../../state/slices/POE/usePoeState";
import { POE_PRICING_TYPE} from "../../../constants/index";
import { LinearProgress } from "@material-ui/core";

const rateService = new RateService(apiClient);

const PayOnEntryContainer = ({
  onAddClick,
  onEditClick,
  entityId,
}) => {
  const classes = useStyles();
  const { offers } = usePoeState();

  const [availableRates, setAvailableRates] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();

  const ITEM_LIMIT = 15;
  const { payonentryData } = usePayOnEntryData();
  const [values, dispatch] = payonentryData;

  const { hasPermissions } = useHasPermissions();
  const EditRoutinePermission = hasPermissions(["payonentry.edit"]);
  const AddRoutinePermission = hasPermissions(["payonentry.add"]);

  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || entityId);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAvailableRates();
  }, [scopeAwareFacilityID]);

  const formatOffers = (offers) => {    
    if (!offers) return [];
    return offers
    .map((offer) => {      
      return {
        id: offer.offerID,
        name: offer.offerName,
        'pricing type': Object.entries(POE_PRICING_TYPE)?.find((x) => x[0] == offer.pricingType)[1],
        fee: offer.pricingType === "Flatfee" ? "$" + offer.fixFee.toFixed(2) : " ",
        duration: offer.duration === 0  ? " " : prettifyDuration(offer.duration),
        rate: availableRates?.find((x) => x.rateID == offer.rateID)?.name ?? " "          
      };
    });
  };

  const getAvailableRates = useCallback(async () => {
    setIsLoading(true);
    rateService
      .getAvailableRates(scopeAwareFacilityID)
      .then(result => {
        setIsLoading(false);
        if (result !== null) {
          setAvailableRates(result.data);
        } else {
          enqueueSnackbar("No rates exist for this entity", {
            variant: "error",
            tag: "NoRatesExistForThisEntity"
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar("Unable to retrieve rates for this entity", {
          variant: "error",
          tag: "FailedToFindRatesForEntity"
        });
      });
  }, [scopeAwareFacilityID]);

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { payonentryPage: page }
    });
  };

  return (
    <>
      <div className={classes.root}>
      {isLoading ? (
          <LinearProgress style={{ width: "100%", opacity: 0.5, marginTop: "2rem" }} data-testid="loading-progress" />
        ) : (
        <ListView
          handleAddButton={onAddClick}
          handleEditButton={onEditClick}
          deleteButtonToggle={false}
          editButtonToggle={EditRoutinePermission ? true : false}
          titleBarTitleText="Pay on Entry"
          titleBarAddButtonToggle={AddRoutinePermission ? true : false}
          data={formatOffers(offers)}
          handlePageChange={handlePageChange}
          currentPage={values?.payonentryPage}
          rowsToShowPerPage={[15, 25, 50]}
          rowsToShowDefault={15}
          paginationToggle={true}
          sortCaseInsensitive={true}
        />
        )}
    </div>
    </>
  );
};

PayOnEntryContainer.defaultProps = {
  onAddClick: () => {},
  onEditClick: () => {},
  entityId: undefined,  
};

PayOnEntryContainer.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  entityId : PropTypes.string,
};

export default PayOnEntryContainer;
