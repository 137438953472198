import React, { useState, useEffect, useCallback } from "react";

/* Material UI */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  AccordionActions,
} from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Services */
import AlertsService from "../../../services/AlertsService";

/* Clients */
import apiClient from "../../../auth/apiClient";

/* Components */
import AlertTable from "./AlertTable";
import Title from "../../Title";

/* State */
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import useHubContext from "../../../hooks/useHubContext";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

/* Hooks */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";

/* Styles */
import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* Utilities */
import { ALERT_CHANGE } from "../../../constants";
import { ALERT_TYPES } from "../../../constants/index";
import { FindEntity } from "../../../state/slices/entities";
import clsx from "clsx";
import useCancellationToken from "../../../hooks/useCancellationToken";
import {selectContextEntity} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectAllOrgTreeEntities} from "../../../state/slices/OrgTree";


const AlertList = ({}) => {
  const alertsService = new AlertsService(apiClient);
  const useCoreEntitySlice = useCoreEntityContext();

  const headCells = [
    { id: "eventName", numeric: false, disablePadding: false, label: "Alert" },
    { id: "name", numeric: false, disablePadding: false, label: "Device" },
    {
      id: "severityID",
      numeric: false,
      disablePadding: false,
      label: "Severity",
    },
    { id: "createdAt", numeric: false, disablePadding: false, label: "Time" },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "Assigned",
    },
  ];
  const headCellsWithFacility = [
    { id: "eventName", numeric: false, disablePadding: false, label: "Alert" },
    { id: "facilityName", numeric: false, disablePadding: false, label: "Facility" },
    { id: "name", numeric: false, disablePadding: false, label: "Device" },
    {
      id: "severityID",
      numeric: false,
      disablePadding: false,
      label: "Severity",
    },
    { id: "createdAt", numeric: false, disablePadding: false, label: "Time" },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "Assigned",
    },
  ];
  const headCellsSmall = [
    { id: "eventName", numeric: false, disablePadding: false, label: "Alert" },
    {
      id: "severityID",
      numeric: false,
      disablePadding: false,
      label: "Severity",
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "Assigned",
    },
  ];

  const theme = useTheme();
  const smallMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState([true]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const isAdmin = hasPermissions(["admin"]);
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const currentUserId = useSelector((state) => state.user.UserID);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("severityID");
  const history = useHistory();
  const classes = useStyles();
  const reportPermission = hasPermissions(["AlarmsReport"]);
  const clearPermission = hasPermissions(["dashboard.alerts.Clear"]);

  const alertsView = hasPermissions(["dashboard.alerts.view"]);
  const { facilityID } = useCurrentFacility();
  const { showDialog } = useConfirmationDialog();
  const currentContextEntity = useSelector((state) => {
    if (useCoreEntitySlice) return selectContextEntity(state);
    let list = state.entities?.EntityIDsRaw ?? [];
    let index = list.findIndex((a) => a.entityid == state.entities?.ContextID);
    if (index !== -1) {
      return list[index];
    }
    return null;
  }, shallowEqual);
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const [scopeEntityIDs, setScopeEntityIDs] = useState([]);
  const scopeList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
    ) ?? [];
  const selectedEntityIDsRaw = useSelector((state) => {

    let list = useCoreEntitySlice ? selectAllOrgTreeEntities(state) : state.entities?.EntityIDsRaw ?? [];
    let currentSelected = state.entityScope?.selected ?? [];
    var idList = currentSelected?.map((entity) => { return entity.id; });

    return list.filter((data) => idList.includes(data.entityid));
  }, shallowEqual);

  const { execute: executeAlertQuery } = useCancellationToken({
    func: getAlerts,
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load alerts", {
        variant: "error",
        tag: "FailedToLoadAlerts",
      });
    },
  });

  useEffect(() => {
    var idList = [];
    if(!facilityGroupID) {
      idList.push(facilityID);
    } else {
      idList = scopeList?.map((entity) => { return entity.id; });
    }
    setScopeEntityIDs(idList);
  }, [facilityGroupID, scopeList, facilityID]);

  useEffect(() => {
    executeAlertQuery();
  }, [scopeEntityIDs])

  async function getAlerts({ cancelToken }) {
    setLoading(true);
    let newAlerts;

    const res = await alertsService.getAlertsForFacilities(scopeEntityIDs, cancelToken);

    newAlerts = res.data.map((a) => {
      let thisAlert = a;
      let additionalInfo = a.payload?.message != null ? a.payload.message : "";
      // add any alert-specific formatting with payload values here:
      switch (a.systemEventID) {
        case ALERT_TYPES.BNR_OUT_OF_DENOMINATION:
          thisAlert.eventName = `${a.eventName} (${additionalInfo})`;
          break;
        default:
        // nothing
      }
      return thisAlert;
    });
    setLoading(false);
    setAlerts(newAlerts);
  }

  const getPropertyCount = (array, property, propertyValue) => {
    var countCalc = array.reduce(
      (n, x) => n + (x[property] === propertyValue),
      0
    );
    return countCalc > 99 ? "99+" : countCalc;
  };

  const handleReportClick = () => {
    history.push(`/reports/AlarmsReport`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const parseSubscribeAlerts = (message) => {
    setAlerts((alertsThatExistAlready) => {
      let newAlerts = [...alertsThatExistAlready];

      let index = newAlerts.findIndex(
        (a) =>
          a.alertID == message.AlertID ||
          (a.entityID == message.EntityID &&
            a.systemEventID == message.systemEventID)
      );

      let composedEventName;
      let additionalInfo =
        message.Payload?.message != null ? message.Payload.message : "";
      switch (message.SystemEventID) {
        case ALERT_TYPES.BNR_OUT_OF_DENOMINATION:
          composedEventName = `${message.EventName} (${additionalInfo})`;
          break;
        default:
          composedEventName = message.EventName;
      }

      if (index !== -1) {
        newAlerts[index].eventName = composedEventName;
        newAlerts[index].entityID = message.EntityID;
        newAlerts[index].nearestFacilityID = message.NearestFacilityID;
        newAlerts[index].nearestFacilityName = message.NearestFacilityName;
        newAlerts[index].status = message.Status;
        newAlerts[index].assignedTo = message.AssignedTo;
        newAlerts[index].assignedAt = message.AssignedAt;
        newAlerts[index].name = message.Name;
        newAlerts[index].firstName = message.FirstName;
        newAlerts[index].lastName = message.LastName;
      } else if (message.Status.toLowerCase() != "closed") {
        let formattedMessage = {
          alertID: message.AlertID,
          entityID: message.EntityID,
          nearestFacilityID: message.NearestFacilityID,
          nearestFacilityName: message.NearestFacilityName,
          status: message.Status,
          assignedTo: message.AssignedTo,
          assignedAt: message.AssignedAt,
          name: message.Name,
          firstName: message.FirstName,
          lastName: message.LastName,
          severityID: message.SeverityID,
          eventName: composedEventName,
          systemEventID: message.SystemEventID,
          createdAt: message.CreatedAt,
          payload: message.Payload,
        };

        newAlerts.push(formattedMessage);
      }

      return [...newAlerts];
    });
  };

  const removeClearedAlertFromList = (alertID) => {
    setAlerts((alertsThatExistAlready) => {
      let newAlerts = [...alertsThatExistAlready];

      let index = newAlerts.findIndex((a) => a.alertID == alertID);

      newAlerts.splice(index, 1);

      return [...newAlerts];
    });
  };

  const handleClaim = async (item) => {
    try {
      var res = await alertsService.claimAlert(item.nearestFacilityID, item.alertID, {
        ClaimedUserID: currentUserId,
        AssignedAt: new Date(),
      });
      setAlerts((alertsThatExistAlready) =>
        alertsThatExistAlready.map((a) =>
          a.alertID === item.alertID ? res.data : a
        )
      );
    } catch {
      enqueueSnackbar("Failed to claim alert", {
        variant: "error",
        tag: "Failed to claim alert",
      });
    }
  };

  const handleUnclaim = async (item) => {
    try {
      var res = await alertsService.unclaimAlert(item.nearestFacilityID, item.alertID);
      setAlerts((alertsThatExistAlready) =>
        alertsThatExistAlready.map((a) =>
          a.alertID === item.alertID ? res.data : a
        )
      );
    } catch {
      enqueueSnackbar("Failed to claim alert", {
        variant: "error",
        tag: "FailedToClaimAlert",
      });
    }
  };

  const handleClear = async (item) => {
    try {
        var res = await alertsService.clearAlert(item.nearestFacilityID, item.alertID, {
        ClearedUserID: currentUserId,
        Resolution: "Cleared from dashboard",
        ClearedAt: new Date(),
      });
      removeClearedAlertFromList(res.data.alertID);
    } catch {
      enqueueSnackbar("Failed to clear alert", {
        variant: "error",
        tag: "FailedToClearAlert",
      });
    }
  };

  const handleClearAll = async () => {
    try {
      const response = await showDialog({
        title: `Clear Alerts`,
        message: `Are you sure you want to clear all alerts?`,
        buttons: [
          { name: "OK", color: "primary" },
          { name: "Cancel", color: "secondary" },
        ],
      });

      if (response === "Cancel") return;

      await alertsService.clearAlertsForFacilities({
        EntityIDs: scopeEntityIDs,
        ClearedUserID: currentUserId,
        Resolution: "Cleared from dashboard",
        ClearedAtDate: new Date(),
      });

      setAlerts([]);
    } catch (e) {
      enqueueSnackbar("Failed to clear alerts", {
        variant: "error",
        tag: "FailedToClearAlerts",
      });
    }
    return;
  };

  headCells.push(
    clearPermission
      ? {
          id: "clearAllButton",
          numeric: false,
          disablePadding: false,
          label: "Clear All",
          isButton: true,
          onClick: handleClearAll,
        }
      : { id: "", numeric: false, disablePadding: false, label: "" }
  );

  headCellsWithFacility.push(
    clearPermission
      ? {
          id: "clearAllButton",
          numeric: false,
          disablePadding: false,
          label: "Clear All",
          isButton: true,
          onClick: handleClearAll,
        }
      : { id: "", numeric: false, disablePadding: false, label: "" }
    );

    headCellsSmall.push(
        clearPermission
            ? {
                id: "clearAllButton",
                numeric: false,
                disablePadding: false,
                label: "Clear All",
                isButton: true,
                onClick: handleClearAll,
            }
            : { id: "", numeric: false, disablePadding: false, label: "" }
    );
  const getHeaderCells = () => {
    return smallMatch ? headCellsSmall : (facilityGroupID && scopeList.length > 1) ? headCellsWithFacility : headCells;
  };

  useEffect(() => {
    if (!alertsView) return;

    portalHub.subscribe(ALERT_CHANGE, (message) => {
      var data = JSON.parse(message);
      var message = JSON.parse(data.Message);

      var deviceEntityUnderCurrentFacility = false;

      if (!facilityGroupID) {
        if (FindEntity(currentContextEntity, message?.EntityID)) {
          deviceEntityUnderCurrentFacility = true;
        }
      } else {
        selectedEntityIDsRaw.forEach(data => {
          if (FindEntity(data, message?.EntityID)){
            deviceEntityUnderCurrentFacility = true;
            return;
          }
        });
      }

      if (!deviceEntityUnderCurrentFacility) {
        // Alerts should not be processed unless they are for devices under the current facility
        return;
      }

      parseSubscribeAlerts(message);
    });

    return () => {
      portalHub.unsubscribe(ALERT_CHANGE);
    };
  }, [PortalHubConnected, currentContextEntity, alertsView, selectedEntityIDsRaw]);

  if (!alertsView) return <></>;

  return (
    <Accordion
      defaultExpanded={true}
      className={clsx("alerts-panel")}
      data-testid="AlertList"
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={clsx("expand-alerts", "button")} />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Title cssSelector="alertsPanelTitle">Alerts</Title>
        <Typography
          className={`${classes.maxWidth} ${classes.centerContentColumn}`}
        >
          <span className={clsx([classes.chipBits, "chip-bits"])}>
            <>
              {!smallMatch && (
                <>
                  <Chip
                    color="secondary"
                    className={clsx([
                      classes.chippy,
                      "critical",
                      "alert-count",
                    ])}
                    data-value={getPropertyCount(alerts, "severityID", 5)}
                    avatar={
                      <Avatar>
                        {getPropertyCount(alerts, "severityID", 5)}
                      </Avatar>
                    }
                    label="Critical"
                  />
                  <Chip
                    color="primary"
                    className={clsx([classes.chippy, "high", "alert-count"])}
                    data-value={getPropertyCount(alerts, "severityID", 4)}
                    avatar={
                      <Avatar>
                        {getPropertyCount(alerts, "severityID", 4)}
                      </Avatar>
                    }
                    label="High"
                  />
                  <Chip
                    color="secondary"
                    className={clsx([classes.chippy, "medium", "alert-count"])}
                    data-value={getPropertyCount(alerts, "severityID", 3)}
                    avatar={
                      <Avatar>
                        {getPropertyCount(alerts, "severityID", 3)}
                      </Avatar>
                    }
                    label="Medium"
                  />
                  <Chip
                    color="secondary"
                    className={clsx([classes.chippy, "low", "alert-count"])}
                    data-value={getPropertyCount(alerts, "severityID", 2)}
                    avatar={
                      <Avatar>
                        {getPropertyCount(alerts, "severityID", 2)}
                      </Avatar>
                    }
                    label="Low"
                  />
                </>
              )}
              {smallMatch && (
                <>
                  <Avatar
                    title="Critical"
                    className={clsx([classes.chip, "critical"])}
                  >
                    {getPropertyCount(alerts, "severityID", 5)}
                  </Avatar>
                  <Avatar title="High" className={clsx([classes.chip, "high"])}>
                    {getPropertyCount(alerts, "severityID", 4)}
                  </Avatar>
                  <Avatar
                    title="Medium"
                    className={clsx([classes.chip, "medium"])}
                  >
                    {getPropertyCount(alerts, "severityID", 3)}
                  </Avatar>
                  <Avatar title="Low" className={clsx([classes.chip, "low"])}>
                    {getPropertyCount(alerts, "severityID", 2)}
                  </Avatar>
                </>
              )}
            </>
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {alerts?.length > 0 ? (
          <AlertTable
            alerts={alerts}            
            headerRow={getHeaderCells()}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            handleUnclaim={handleUnclaim}
            handleClaim={handleClaim}
            handleClear={handleClear}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isAdmin={isAdmin}
            showFacility={ facilityGroupID && scopeList.length > 1 }
          />
        ) : (
          <>
            {!loading ? (
              <Typography
                className={clsx("no-alerts", "text", classes.reportText)}
              >
                There are no alerts to display.
              </Typography>
            ) : (
              <LinearProgress style={{ width: "100%", opacity: 0.5 }} />
            )}
          </>
        )}
      </AccordionDetails>
      <AccordionActions>
        {reportPermission && (
          <Link
            style={{ padding: 4 }}
            data-testid="AlertsReportLink" //get rid of this line when QA sorts their ATs
            onClick={handleReportClick}
            className={clsx(["alerts-report-link", classes.pointer])}
          >
            Alarms and Alerts Report
          </Link>
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default AlertList;
