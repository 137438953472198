import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useStyles} from "./ValetInventoryDetailDrawer.styles";
import {
  Typography,
  Divider,
  Button,
  Drawer,
} from "@material-ui/core";
import TicketDetails from "./TicketDetails/TicketDetails";
import HistoryComponent from "./HistoryComponent/HistoryComponent";


const ValetInventoryDetailDrawer = ({credential, open, onClose}) => {
  const classes = useStyles();

	  return (
    <Drawer
      className={clsx("drawer", classes.drawer)}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
    >
      <div className={classes.cssGridContainer}>
        <div className={classes.cssGrid}>
          <div>
            <Typography
              variant="h5"
              color="primary"
              component="h2"
              className={clsx(classes.title)}
            >
              Inventory Detail
            </Typography>
          </div>
          <Divider style={{marginBottom: 15}}></Divider>
          <div>
            <div className={clsx(classes.detailContainer)}>
                <TicketDetails credentialId={credential?.credentialId}/>
            </div>
            <div style={{marginTop: "2rem"}}>
              <HistoryComponent credentialId={credential?.credentialId}></HistoryComponent>
            </div>
          </div>
          <Divider></Divider>
          <div style={{paddingRight: "8px"}}>
          <Button
        style={{float: "right"}}
        data-id="closeBtn"
        name="close"
        variant="contained"
        onClick={onClose}
      >
        Close
      </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ValetInventoryDetailDrawer;
