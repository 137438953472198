import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarSide as LoopsIcon,
  faPrint as PrinterIcon,
  faMoneyBill as BankNoteIcon,
  faCreditCard as CreditCardTerminalIcon,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle as WheelIcon } from "@fortawesome/pro-solid-svg-icons";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useStyles } from "./EntityIconBar.styles";
import _ from "lodash";
import clsx from "clsx";

import {
  FindEntity,
  DetermineErrorClassesByPeripheralType,
} from "../../../state/slices/entities";
import * as c from "../../../constants";
import { Tooltip } from "@material-ui/core";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {selectOrgTreeEntityById} from "../../../state/slices/OrgTree";

const EntityIconBar = ({ entityID, disabledFeature, ...props }) => {
  const classes = useStyles();
  const useCoreEntitySlice = useCoreEntityContext()

  const entity =
    useSelector(
      (state) => {
        let _entity = useCoreEntitySlice ? selectEntityById(state,entityID) : FindEntity(state.entities?.EntityList ?? [], entityID);
        if(useCoreEntitySlice && !_entity?.entityid){
          _entity = selectOrgTreeEntityById(state, entityID);
        }
        return {
          entityid: _entity?.entityid ?? null,
          name: _entity?.name ?? null,
          type: _entity?.type ?? null,
          typeid: _entity?.typeid ?? null,
          state: _entity?.state ?? null,
          online: (_entity?.state != null ? true : false) ?? false,
          armingLoop:
            _entity?.state?.Peripherals?.Gate?.ArmingLoop ?? c.LOOP_STATUS.Unset, //0 unknown, 1 active, 2 inactive
          closingLoop:
            _entity?.state?.Peripherals?.Gate?.ClosingLoop ?? c.LOOP_STATUS.Unset, //0 unknown, 1 active, 2 inactive
          printer: _entity?.state?.Peripherals?.Printer ?? {},
          deviceMode:
            _.find(_entity?.settings ?? [], { name: "devicemode" })?.value ??
            null,
          poeEnabled:
            _.find(_entity?.settings ?? [], {
              name: "poe.enabled",
            })?.value?.toUpperCase() === "TRUE" ?? false,
          printerConfigured:
            _entity?.state?.Peripherals?.Printer?.IsConfigured ?? false,
          bnrConfigured:
            _entity?.state?.Peripherals?.BNR?.IsConfigured ?? false,
          ccReaderConfigured:
            _entity?.state?.Peripherals?.CreditCardReader?.IsConfigured ??
            false,
          gateConfigured:
            _entity?.state?.Peripherals?.Gate?.IsConfigured ?? false,
        };
      },
      (one, two) => JSON.stringify(one) === JSON.stringify(two)
    ) ?? {};
  
  const renderDeviceMode = () => {
    switch (entity.deviceMode) {
      case c.DEVICE_MODE.KIOSK:
        return "PAY ON FOOT";
      case c.DEVICE_MODE.ENTRY:
        return entity.poeEnabled ? "PAY ON ENTRY" : "ENTRY";
      default:
        return entity.deviceMode.toUpperCase();
    }
  };

  return (
    <>
      {(entity.typeid == c.ENTITY_TYPE.Device && props.isBeingListened)&& (
        <div
          className={classNames(
            classes.iconBar,
            !entity.online ? classes.querying : classes.queried
          )}
        >
          {entity.gateConfigured && (
            <span
              className={classNames([classes.car, "fa-layers", "fa-fw"])}
              title={`Arming Loop [${
                { 0: "??", 1: "ON", 2: "OFF" }[entity.armingLoop]
              }]\nClosing Loop [${
                { 0: "??", 1: "ON", 2: "OFF" }[entity.closingLoop]
              }]`}
            >
              <FontAwesomeIcon
                icon={LoopsIcon}
                className={clsx([
                  classes.iconBarIcon,
                  !entity.online ? "offline" : "",
                  entity.armingLoop === c.LOOP_STATUS.Active ||
                  entity.closingLoop === c.LOOP_STATUS.Active
                    ? "loop-on"
                    : "",
                  ...DetermineErrorClassesByPeripheralType(
                    entity,
                    c.ERROR_TYPES.ClosingLoop
                  ),
                  ...DetermineErrorClassesByPeripheralType(
                    entity,
                    c.ERROR_TYPES.ArmingLoop
                  ),
                ])}
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                transform="right-29.9 down-15.2"
                className={clsx([
                  { 0: "unknown", 1: "on", 2: "off" }[entity.armingLoop],
                  classes.wheel,
                  "front",
                  "arming",
                ])}
                icon={WheelIcon}
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                transform="right--6.2 down-15.2"
                className={clsx([
                  { 0: "unknown", 1: "on", 2: "off" }[entity.closingLoop],
                  classes.wheel,
                  "rear",
                  "closing",
                ])}
                icon={WheelIcon}
              ></FontAwesomeIcon>
            </span>
          )}
          {entity.printerConfigured && (
            <FontAwesomeIcon
              icon={PrinterIcon}
              title="Printer"
              className={classNames(
                classes.iconBarIcon,
                !entity.online ? "offline" : "",
                ...DetermineErrorClassesByPeripheralType(
                  entity,
                  c.ERROR_TYPES.Printer
                )
              )}
            ></FontAwesomeIcon>
          )}
          {entity.bnrConfigured && (
            <FontAwesomeIcon
              title="Bank Note Recycler"
              icon={BankNoteIcon}
              className={clsx([
                classes.iconBarIcon,
                !entity.online ? "offline" : "",
                ...DetermineErrorClassesByPeripheralType(
                  entity,
                  c.ERROR_TYPES.BNR
                ),
              ])}
            ></FontAwesomeIcon>
          )}
          {entity.ccReaderConfigured && (
            <FontAwesomeIcon
              title="Credit Card Terminal"
              icon={CreditCardTerminalIcon}
              className={clsx([
                classes.iconBarIcon,
                !entity.online ? "offline" : "",
                ...DetermineErrorClassesByPeripheralType(
                  entity,
                  c.ERROR_TYPES.CCReader
                ),
              ])}
            ></FontAwesomeIcon>
          )}
        </div>
      )}
      {entity.typeid == c.ENTITY_TYPE.Device && !_.isEmpty(disabledFeature) && (
        <>
          <Tooltip
            title={disabledFeature + " is disabled"}
            className={classes.featureTileTooltip}
          >
            <div
              data-testid="device-feature-status"
              className={classes.featureTile}
            >
              DISABLED
            </div>
          </Tooltip>
        </>
      )}
      {entity.typeid == c.ENTITY_TYPE.Device && !props.hideType && (
        <>
          <div data-testid="device-mode" className={classes.deviceTile}>
            {renderDeviceMode()}
          </div>
        </>
      )}
    </>
  );
};

EntityIconBar.defaultProps = { 
  entityID: null,
  isBeingListened: true
};
EntityIconBar.propTypes = { 
  entityID: PropTypes.string,
  isBeingListened: PropTypes.bool
};

export default EntityIconBar;
