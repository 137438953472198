import React from "react";

/* Components */
import { Button, Typography, Grid } from "@material-ui/core";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays as calendarIcon,
  faBarcode as barcodeIcon,
  faTicket as ticketIcon,
  faBellExclamation as requestIcon,
  faCar as retrievalIcon,
  faCircleCheck as deliveryIcon
} from "@fortawesome/pro-regular-svg-icons";

/* Style */
import clsx from "clsx";

/* State */
import useCashierOffers from "../Utilities/useCashierOffers";
import { TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

const CashieredDeviceSelectEntryType = ({
  classes,
  onSelectOfferClick,
  onScanCredentialClick,
  onScanTicketClick,
  isValetHandheldSelected,
}) => {

  const { selectedOffers } = useCashierOffers();
  const hasNoOffers = selectedOffers === undefined || selectedOffers?.length < 1;

  return (
    <>
      {!isValetHandheldSelected && (
        <div className={classes.step} data-testid="start-step">
          {!hasNoOffers && (
            <Button
              data-testid="selectOffer"
              className={clsx(
                classes.entryChoiceButton,
                classes.offersChoiceButton
              )}
              disabled={hasNoOffers}
              onClick={onSelectOfferClick}
            >
              <FontAwesomeIcon
                className={classes.entryChoiceButtonIcon}
                icon={calendarIcon}
              ></FontAwesomeIcon>
              <div className={classes.entryChoiceButtonText}>
                <Typography variant="h5" component="div">
                  Offers
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Select from a list of pre-pay events / offers.
                </Typography>
              </div>
            </Button>
          )}
          <Button
            data-testid="scanCredential"
            className={clsx(
              classes.entryChoiceButton,
              classes.credentialChoiceButton
            )}
            onClick={onScanCredentialClick}
          >
            <FontAwesomeIcon
              className={classes.entryChoiceButtonIcon}
              icon={barcodeIcon}
            ></FontAwesomeIcon>
            <div className={classes.entryChoiceButtonText}>
              <Typography variant="h5" component="div">
                Scan Credential
              </Typography>
              <Typography variant="subtitle1" component="div">
                Scan a credential presented by the customer.
              </Typography>
            </div>
          </Button>
        </div>
      )}
      {isValetHandheldSelected && (
        <div className={classes.valetEntryTypeStep} data-testid="valet-start-step">
          {/* 
              Normal View:
                  Grid configured with three rows and three columns.
                  To meet the requirement needs, second row first column made empty.
              Mobile View: 
                  Grid configured to show four rows each with single column 
                  so that all modules will be shown vertically      
          */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Button
                data-testid="arrival"
                className={clsx(classes.valetEntryChoiceButton, classes.arrivalChoiceButton)}
                onClick={() => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.ARRIVAL)}
              >
                <FontAwesomeIcon
                  className={classes.entryChoiceButtonIcon}
                  icon={ticketIcon}
                />
                <div className={classes.valetEntryChoiceButtonText}>
                  <Typography variant="h5" component="div">
                    Arrival
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    Scan valet ticket to arrive a car.
                  </Typography>
                </div>
              </Button>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Button
                data-testid="request"
                className={clsx(classes.valetEntryChoiceButton, classes.requestChoiceButton)}
                onClick={() => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.REQUEST)}
              >
                <FontAwesomeIcon
                  className={classes.entryChoiceButtonIcon}
                  icon={requestIcon}
                />
                <div className={classes.valetEntryChoiceButtonText}>
                  <Typography variant="h5" component="div">
                    Pay Request
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    Scan valet ticket to request a car.
                  </Typography>
                </div>
              </Button>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Button
                data-testid="retrieval"
                className={clsx(classes.valetEntryChoiceButton, classes.nonActionableButton, classes.retrievalChoiceButton)}
              >
                <FontAwesomeIcon
                  className={classes.entryChoiceButtonIcon}
                  icon={retrievalIcon}
                />
                <div className={classes.valetEntryChoiceButtonText}>
                  <Typography variant="h5" component="div">
                    Retrieval
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    Scan valet ticket to retrieve a car.
                  </Typography>
                </div>
              </Button>
            </Grid>
            {/* Grid item with empty content will be shown in normal view .
                Where as in mobile view, this grid item will be hidden to show all modules consecutively. */}
            <Grid item xs={12} sm={4} className={clsx(classes.gridItem, classes.hideInMobile)}>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Button
                data-testid="delivery"
                className={clsx(classes.valetEntryChoiceButton, classes.nonActionableButton, classes.deliveryChoiceButton)}
              >
                <FontAwesomeIcon
                  className={classes.entryChoiceButtonIcon}
                  icon={deliveryIcon}
                />
                <div className={classes.valetEntryChoiceButtonText}>
                  <Typography variant="h5" component="div">
                    Delivery
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    Scan valet ticket to deliver a car.
                  </Typography>
                </div>
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
export default CashieredDeviceSelectEntryType;
