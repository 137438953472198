import {createAsyncThunk} from "@reduxjs/toolkit";
import apiClient from "../../../auth/apiClient";
import {applyPermissions} from "./CoreEntityUtil";
import _ from "lodash";
import FacilityService from "../../../services/FacilityService";
import ValetAreaService from "../../../services/ValetAreaService";
import {updateOrgEntity, updateOrgTree} from "../OrgTree";

const facilityService = new FacilityService(apiClient);
const valetAreaService = new ValetAreaService(apiClient);

/**
 * Fetches a new facility and its children if it doesn't exist in state and sets context to the new facility.
 *
 * @param {Object} param
 * @param {Number} param.userID - The ID of the user to get permissions for.
 * @param {Number} param.facilityID - The ID of the facility to change to.
 *
 * @returns {Object} A Promise that resolves with the contextID of the new facility, the permissions for the user at that facility, and an array of new entities to add to the state.
 */
export const changeNewFacilities = createAsyncThunk(
    "coreEntities/facilities/change",
    async ({ userID, facilityID }, thunkAPI) => {
        try {
            const entityResponse = await apiClient.get(`entities/${facilityID}/tree`);
            const entities = entityResponse.data;
            const permissionsResponse = await apiClient.get(`accounts/v2/permissions/${userID}?entityID=${facilityID}`);
            const permissions = permissionsResponse.data;
            return { contextID: facilityID, contextPermissions: permissions, entitiesToAdd: entities };

        } catch (error) {
            console.error("Error in changeNewFacilities:", error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


export const addNewEntities = createAsyncThunk(
    "coreEntities/add",
    async ({ entityID }, thunkAPI) => {
        try {
            const response = await apiClient.get(`entities/${entityID}/tree`);

            if (!response || !response.data || response.data.length === 0) {
                return;
            }

            // since this endpoint returns the entire tree from top to bottom, just find the node we originally cared about and its children
            const entitiesToAdd = response.data

            return { entityId: entityID, newEntitiesToAdd: entitiesToAdd };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

/**
 * Adds permissions to an entity.
 *
 * @param {Object} param
 * @param {Number} param.userID - The ID of the user to fetch permissions for.
 * @param {Number} param.entityID - The ID of the entity to add permissions to.
 * @param {Number} [param.currentContextID] - The ID of the current context to fetch entity data from.
 *
 * @returns {Object} A Promise that resolves with the updated entity data.
 */
export const addPermissionsToEntity = createAsyncThunk(
    `coreEntities/permissions`,
    async ({ userID, entityID, currentContextID }, thunkAPI) => {
        if (_.isNil(currentContextID)) {
            // Maintain backwards compatibility, this variable is not needed for non-FG contexts
            currentContextID = entityID;
        }

        const state = thunkAPI.getState();
        const { coreEntities } = state;
        let foundEntity = coreEntities.entities[entityID];

        // Fetch entity data if not found
        if (!foundEntity) {
            const response = await apiClient.get(`entities/${currentContextID}/rich`);
            foundEntity = response.data; // Assuming response structure has the entity data
        }

        // Fetch permissions
        const permissionsResponse = await apiClient.get(
            `accounts/v2/permissions/${userID}?entityID=${entityID}`
        );
        const fetchedPermissions = permissionsResponse.data[0];

        // Create a new copy of the entity with updated permissions
        const updatedEntity = {
            ...foundEntity,
            permissions:[
                ...fetchedPermissions.permissions?.map(
                    (permission) => permission.permissionName
                ),
                ...fetchedPermissions.groups
                    ?.map((group) => [...group.permissions])
                    .flat(),
            ] ?? [], // Add/replace the permissions
        };
        // Return the updated entity
        if (updatedEntity && updatedEntity.entityid) {
            return updatedEntity;
        } else {
            return thunkAPI.rejectWithValue({ error: 'Invalid entity structure' });
        }
    }
);

/**
 * Updates multiple settings on an entity. no redux action for this
 *
 * @param {Object[]} bulkSettings - The settings to update.
 * @param {String} bulkSettings.userName - The username of the user performing the update.
 * @param {Number} bulkSettings.entityId - The ID of the entity to update.
 * @param {String} bulkSettings.settingName - The name of the setting to update.
 * @param {String|Number|Boolean} bulkSettings.settingValue - The value to set the setting to.
 *
 * @returns {Object} A Promise that resolves with the updated entity data.
 */
export const updateEntitySettings = createAsyncThunk(
    "coreEntities/updateEntitySettings",
    async (bulkSettings, thunkAPI) => {
        if (_.isNil(bulkSettings) || !_.isArray(bulkSettings)) {
            return thunkAPI.rejectWithValue({ error: 'Invalid bulk settings' });
        }

        if (bulkSettings[0] && bulkSettings[0].settingName === "setting.devicedisabled") {
            bulkSettings.push({
                userName: bulkSettings[0].userName,
                entityId: bulkSettings[0].entityId,
                settingName: "setting.laneclosedsign",
                settingValue: bulkSettings[0].settingValue,
            });
        }

        try {
            const response = await apiClient.put(
                "v2/settings/bulk",
                bulkSettings,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const deleteEntitySettings = createAsyncThunk(
    "coreEntities/deleteEntitySetting",
    async ({ entityId, settingName, settingValue }, thunkAPI) => {
        if (_.isNil(entityId) || _.isNil(settingName)) {
            return thunkAPI.rejectWithValue({
                error: 'Missing required parameters',
            });
        }

        try {
            const response = await apiClient.delete(
                `v2/settings/${entityId}/${settingName}`,
                {
                    data: {
                        settingValue,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const updateEntity = createAsyncThunk(
    "coreEntities/entityUpdateGeneric",
    async (payload, thunkAPI) => {
        try {
            const response = await apiClient.put(payload.path, payload.entity, {
                headers: { "Content-Type": "application/json" },
            });

            const richEntity = await apiClient.get(
                "entities/" +
                (response.data?.deviceID ??
                    response.data?.facilityID ??
                    response.data?.valetAreaID ??
                    response.data?.areaID ??
                    response.data) +
                "/rich/"
            );
            const state = thunkAPI.getState();
            const { coreEntities } = state;

            if(richEntity.data?.entityid && !coreEntities.entities[richEntity.data.entityid]) {
                thunkAPI.dispatch(updateOrgEntity(richEntity.data));
                return null
            }

            return richEntity.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const onboardNewFacility = createAsyncThunk(
    "coreEntities/facility/onboard",
    async (payload, thunkAPI) => {
        try {
            let result = await facilityService.onboardFacility(
                payload.parentEntityID,
                payload
            );

            if(result.data?.facilityID) {
                result = await apiClient.get(`entities/${result.data.facilityID}/rich`);
            }

            return result.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const onboardValetArea = createAsyncThunk(
    "coreEntities/valetarea/onboard",
    async (payload, thunkAPI) => {

        try {
            let result = await valetAreaService.onboardValetArea(payload);

            if(result.data?.valetAreaID) {
                result = await apiClient.get(`entities/${result.data.valetAreaID}/rich`);
            }

            return result.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const createNewEntity = createAsyncThunk(
    "coreEntities/createEntityGeneric",
    async (payload, thunkAPI) => {
        try {
            if (_.isNil(payload.entity)) {
                return thunkAPI.rejectWithValue({
                    error: 'Missing required parameters',
                });
            }

            const response = await apiClient.post(payload.path, payload.entity, {
                headers: { "Content-Type": "application/json" },
            });
            const richEntity = await apiClient.get(
                "entities/" +
                (response.data?.deviceID ??
                    response.data?.facilityID ??
                    response.data?.areaID ??
                    response.data) +
                "/rich/"
            );
            return richEntity.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

export const deleteEntity = createAsyncThunk(
    "coreEntities/deleteEntityGeneric",
    async (payload, thunkAPI) => {
        try {
            if (_.isNil(payload.path) || _.isNil(payload.payload.entityid)) {
                return thunkAPI.rejectWithValue({
                    error: 'Missing required parameters',
                });
            }

            const response = await apiClient.patch(
                payload.path,
                [payload.payload.entityid],
                { headers: { "Content-Type": "application/json" } }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                error: _.get(error, 'response.data.error', 'Unknown error'),
            });
        }
    }
);

/**
 * Retrieves all entities at a given level and dispatches updateOrgTree to add the
 * retrieved entities to the state.
 *
 * @param {Object} param
 * @param {Number} param.entityID - The ID of the entity to start at.
 * @param {Number} param.userID - The ID of the user to get permissions for.
 * @param {Number} param.currentContextID - The ID of the context to get entities for.
 *
 * @returns {Object} A Promise that resolves with the parent ID of the last
 * entity in the tree and the built tree.
 */
export const fillEntityLevel = createAsyncThunk(
    "CoreEntities/fill",
    async ({ entityID, userID, currentContextID }, thunkAPI) => {
        if (_.isNil(currentContextID)) {
            // maintain backwards compatability, this variable is not needed for non-FG contexts
            currentContextID = entityID;
        }
        const state = thunkAPI.getState();
        const { coreEntities } = state;
        // fetch all entities under the given context


        const nodes = await apiClient.get(`entities/${currentContextID}/tree`);
        const built = nodes.data;
        let neededNodes;
        if(built.length > 0) {
            built.forEach((entity) => {
                if (entity.entityid == currentContextID) {
                    neededNodes = entity;
                }
            })
        }
        // get all permissions for the given context
        const entityPermissions = await apiClient.get(
            `accounts/v2/permissions/${userID}?entityID=${entityID}`
        );
        // grab permissions that need set/updated/maintained in state.EntityList
        let permissionDict = {};
        entityPermissions.data.forEach((grouping) => {
            permissionDict[grouping.entityID] = grouping.permissions.map(
                (permission) => permission.permissionName
            );
        });
        neededNodes = applyPermissions(permissionDict, neededNodes);

        thunkAPI.dispatch(updateOrgTree({
            entities: built,
            childrenPermissions: permissionDict
        }));

        if(!coreEntities.entities[entityID]){
            return
        }
        const parentID =
            neededNodes.parententityid == null
                ? neededNodes.entityid
                : neededNodes.parententityid;

        return { built, parentID };
    }
);


/**
 * Updates multiple settings on an entity. no redux action for this
 *
 * @param {Object[]} settings - The settings to update.
 * @param {String} settings.userName - The username of the user performing the update.
 * @param {Number} settings.entityId - The ID of the entity to update.
 * @param {String} settings.settingName - The name of the setting to update.
 * @param {String|Number|Boolean} settings.settingValue - The value to set the setting to.
 *
 * @returns {Object} A Promise that resolves with the updated entity data.
 */
export const setSettings = createAsyncThunk(
    "coreEntities/setSettings",
    async (settings, thunkAPI) => {

        const state = thunkAPI.getState();
        const { orgTree, coreEntities } = state;

        const groupedSettings = _.groupBy(settings, "entityID");

        const updatedEntities = Object.entries(groupedSettings).map(
            ([entityID, entitySettings]) => {
                let foundEntity = coreEntities.entities[entityID];
                if (!foundEntity?.entityid) foundEntity = orgTree.entities[entityID];

                if (!foundEntity) {
                    return null; // no entity found
                }


                const updatedEntity = {
                    ...foundEntity,
                    settings: foundEntity.settings.map((s) => {
                        const update = entitySettings.find(
                            (setting) =>
                                s.name.toLowerCase() ===
                                setting.settingName.toLowerCase().replace("setting.", "")
                        );
                        return update ? { ...s, value: update.settingValue } : s;
                    }),
                };

                return updatedEntity;
            }
        ).filter(entity => entity !== null);

        if (!updatedEntities.length) {
            return thunkAPI.rejectWithValue({
                error: `No updates found`
            });
        }

        return updatedEntities; // Return the updated entities as an array
    }
);


/**
 * Updates a single setting on an entity.
 *
 * @param {Object} param
 * @param {Number} param.entityid - The ID of the entity to update.
 * @param {String} param.settingName - The name of the setting to update.
 * @param {String|Number|Boolean} param.settingValue - The value to set the setting to.
 *
 * @returns {Object} A Promise that resolves with the updated entity data.
 */
export const setSetting = createAsyncThunk(
    "coreEntities/setSetting",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState();
        const { orgTree, coreEntities } = state;
        const { entityid, settingName, settingValue } = payload;
        let foundEntity = coreEntities.entities[entityid];

        if (!foundEntity) {
            foundEntity = orgTree.entities[entityid];
        }

        if (!foundEntity) {
            return thunkAPI.rejectWithValue({
                error: `No entity found with ID: ${entityid}`,
            });
        }

        const updatedEntity = { ...foundEntity };

        const setting = updatedEntity.settings?.find(
            (x) =>
                x.name.toLowerCase() === settingName.toLowerCase()
        );

        if (!setting) {
            return thunkAPI.rejectWithValue({
                error: `No setting found with name: ${settingName}`,
            });
        }

        setting.value = settingValue;

        return updatedEntity;
    }
);