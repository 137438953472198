import _ from "lodash";
import * as c from "../../../../constants";


export const castBool = (setting) => {
    if (!setting) return false;
    if (typeof setting.value === "string")
        return setting.value === "true" ?? false;
    if (typeof setting.value === "boolean") return setting.value;
    return false;
};


export const extractCommonSettings = (entity) => {
    return {
        displaylotfull: _.find(entity?.settings ?? [], { name: "displaylotfull" })
            ?.value ?? "",
        backouttimeout:
            _.find(entity?.settings ?? [], { name: "backouttimeout" })?.value ?? 0,
        autoprintreceipt:
            _.find(entity?.settings ?? [], { name: "autoprintreceipt" })?.value ==
            "true",
        ticket_footer:
            _.find(entity?.settings ?? [], { name: "ticket.footer" })?.value ?? "",
        ticket_header:
            _.find(entity?.settings ?? [], { name: "ticket.header" })?.value ?? "",
        receipt_footer:
            _.find(entity?.settings ?? [], { name: "receipt.footer" })?.value ?? "",
        receipt_header:
            _.find(entity?.settings ?? [], { name: "receipt.header" })?.value ?? "",
        toidletimeout:
            _.find(entity?.settings ?? [], { name: "toidletimeout" })?.value ?? 0,
        centralpaytrxtimeout:
            _.find(entity?.settings ?? [], { name: "centralpaytrxtimeout" })
                ?.value ?? 0,
        thirdpartyaccessgroup:
            _.find(entity?.settings ?? [], { name: "thirdpartyaccessgroup" })
                ?.value ?? 0,
        thirdpartyrate:
            _.find(entity?.settings ?? [], { name: "thirdpartyrate" })?.value ?? 0,
        issueticketwhilefull:
            _.find(entity?.settings ?? [], { name: "issueticketwhilefull" })
                ?.value == "true",
        duplicatecredentialreaddelay:
            _.find(entity?.settings ?? [], { name: "duplicatecredentialreaddelay" })
                ?.value ?? 0,
        hotelinterface:
            _.find(entity?.settings ?? [], { name: "hotelinterface" })?.value ??
            "{}",
        intercomprovider:
            _.find(entity?.settings ?? [], { name: "intercomprovider" })?.value ??
            1,
        poeEnabled:
            _.find(entity?.settings ?? [], { name: "poe.enabled" })?.value ===
            "true" ?? false,
        poeAttendedMode:
            _.find(entity?.settings ?? [], { name: "poe.attendedmode" })?.value ===
            "true" ?? false,
        poeShowTicketButton:
            _.find(entity?.settings ?? [], { name: "poe.showticketbutton" })
                ?.value === "true" ?? false,
        poeOffers:
            _.find(entity?.settings ?? [], { name: "poeoffers" })?.value ?? [],
        lprConfidenceScore:
            _.find(entity?.settings ?? [], { name: "lpr.confidencescore" })
                ?.value ?? 0,
        lprMatchAccuracy:
            _.find(entity?.settings ?? [], { name: "lpr.matchaccuracy" })?.value ??
            0,
        lprCredential:
            _.find(entity?.settings ?? [], { name: "lpr.credential" })?.value ===
            "true" ?? false,
        lprCalculateFee:
            _.find(entity?.settings ?? [], { name: "lpr.calculatefee" })?.value ===
            "true" ?? false,
        lprHotelGuestAutoEnroll:
            _.find(entity?.settings ?? [], { name: "lpr.hotelguestautoenroll" })?.value ===
            "true" ?? false,
        lprTicketless:
            _.find(entity?.settings ?? [], { name: "lpr.ticketless" })?.value ===
            "true" ?? false,
        lprTicketLessExceptionFeeDelay:
            _.find(entity?.settings ?? [], { name: "lpr.ticketlessexceptionfeedelay" })
                ?.value ?? 0,
        lprTicketLessExceptionFeeDuration:
            _.find(entity?.settings ?? [], { name: "lpr.ticketlessexceptionfeeduration" })
                ?.value ?? 0,
        lprTicketlessEntryCaptureMode:
            _.find(entity?.settings ?? [], { name: "lpr.ticketlessentrycapturemode" })
                ?.value ?? 1,
        continuousCallCenterNotification: castBool(
            _.find(entity?.settings ?? [], { name: "intercom.continuousring" })
        ),
        ticketmasterurl:
            _.find(entity?.settings ?? [], { name: "ticketmasterurl" })?.value ?? "",
        ticketmastertoken:
            _.find(entity?.settings ?? [], { name: "ticketmastertoken" })?.value ?? "",
        ticketmastervenueid:
            _.find(entity?.settings ?? [], { name: "ticketmastervenueid" })?.value ?? "",
        ticketmastergatename:
            _.find(entity?.settings ?? [], { name: "ticketmastergatename" })?.value ?? "",
        onlinevalidationlimitperticketenabled:
            _.find(entity?.settings ?? [], { name: "onlinevalidationlimitperticketenabled" })
                ?.value == "true" ?? false,
        onlinevalidationlimitperticket:
            _.find(entity?.settings ?? [], { name: "onlinevalidationlimitperticket" })
                ?.value ?? 1,
        allowtransactionswhilegateraised:
            _.find(entity?.settings ?? [], { name: "allowtransactionswhilegateraised" })
                ?.value == "true" ?? false,
    };
};

export const extractTypeSpecificSettings = (entity) => {
    switch (entity?.typeid) {
        case c.ENTITY_TYPE.Device:
            return {
                devicemode: _.find(entity?.settings ?? [], { name: "devicemode" })
                    ?.value,
                devicedisabled:
                    _.find(entity?.settings ?? [], { name: "devicedisabled" })?.value ==
                    "true" ?? false,
                laneclosedsign:
                    _.find(entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
                    "true",
                ccdeviceid: _.find(entity?.settings ?? [], { name: "ccdeviceid" })
                    ?.value,
                parker_user: _.find(entity?.settings ?? [], { name: "parker.user" })
                    ?.value,
                parker_password: _.find(entity?.settings ?? [], {
                    name: "parker.password",
                })?.value,
                valetdeviceid: _.find(entity?.settings ?? [], {
                    name: "valetdeviceid",
                })?.value,
                ticketmastermacaddress:
                    _.find(entity?.settings ?? [], { name: "ticketmastermacaddress" })?.value ?? "",
                ticketmasterdeviceid:
                    _.find(entity?.settings ?? [], { name: "ticketmasterdeviceid" })?.value ?? "",
            };
        case c.ENTITY_TYPE.Facility:
            return {
                ccreplyto:
                    _.find(entity?.settings ?? [], { name: "ccreplyto" })?.value ==
                    "true",
                emailreply:
                    _.find(entity?.settings ?? [], { name: "emailreply" })?.value ?? "",
                emailfrom:
                    _.find(entity?.settings ?? [], { name: "emailfrom" })?.value ?? "",
                emaildisplayfrom:
                    _.find(entity?.settings ?? [], { name: "emaildisplayfrom" })?.value ?? "",
                emailtemplate:
                    _.find(entity?.settings ?? [], { name: "emailtemplate" })?.value ??
                    "",
                shortchangeenabled:
                    _.find(entity.settings, { name: "shortchangeenabled" })?.value ===
                    "true" ?? false,
                shortchangeinstructions:
                    _.find(entity.settings, { name: "shortchangeinstructions" })
                        ?.value ??
                    "We are sorry for the inconvenience! This machine did not have enough cash to give you the correct change. Please contact the parking office to collect the remaining change shown above.",
                receipt_footer:
                    _.find(entity?.settings ?? [], { name: "receipt.footer" })?.value ??
                    "",
                receipt_header:
                    _.find(entity?.settings ?? [], { name: "receipt.header" })?.value ??
                    "",
                lostticketallowvalidations:
                    _.find(entity?.settings ?? [], {
                        name: "lostticketallowvalidations",
                    })?.value == "true",
                lprImageRetention:
                    _.find(entity?.settings ?? [], { name: "lpr.imageretention" })
                        ?.value ?? 0,
                lprTextRetention:
                    _.find(entity?.settings ?? [], { name: "lpr.textretention" })
                        ?.value ?? 0,
                lprMostRecentMatch:
                    _.find(entity?.settings ?? [], { name: "lpr.mostrecentmatch" })?.value ===
                    "true" ?? false,
                ticketmastermacaddress:
                    _.find(entity?.settings ?? [], { name: "ticketmastermacaddress" })?.value ?? "",
                ticketmasterdeviceid:
                    _.find(entity?.settings ?? [], { name: "ticketmasterdeviceid" })?.value ?? "",
                offlinefunctionalityenabled: castBool(_.find(entity?.settings ?? [], { name: "offlinefunctionalityenabled" })),
                exitofflinemessage:
                    _.find(entity?.settings ?? [], { name: "exitofflinemessage" })
                        ?.value ?? "",
                offlineticketexitfunction:
                    _.find(entity?.settings ?? [], { name: "offlineticketexitfunction" })
                        ?.value ?? 1,
                startOfDayWeekForReport:
                    _.find(entity?.settings ?? [], { name: "startOfDayWeekForReport" })
                        ?.value ?? "",
            };
        case c.ENTITY_TYPE.FacilityGroup:
            return {
                ccreplyto:
                    _.find(entity?.settings ?? [], { name: "ccreplyto" })?.value ==
                    "true",
                emailreply:
                    _.find(entity?.settings ?? [], { name: "emailreply" })?.value ?? "",
                emaildisplayfrom:
                    _.find(entity?.settings ?? [], { name: "emaildisplayfrom" })?.value ?? "",
                emailtemplate:
                    _.find(entity?.settings ?? [], { name: "emailtemplate" })?.value ??
                    "",
                valetareaticketlength:
                    _.find(entity?.settings ?? [], { name: "valetareaticketlength" })?.value ?? 0
            };
        case c.ENTITY_TYPE.ValetArea:
            return {
                valetareaticketlength:
                    _.find(entity?.settings ?? [], { name: "valetareaticketlength" })?.value ?? 0,
            };
    }
};
