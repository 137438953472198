import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  stepperButtonPlaceholder: {
    width: theme.spacing(8.75),
    height: theme.spacing(4),
  },
  stepperButton: {
    width: theme.spacing(8.75),
    height: theme.spacing(4),
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    height: "100%",
    maxHeight: "calc(100vh - 7.5rem)",
    position: "relative",
    padding: "0rem",
    alignItems: "stretch",
  },
  step: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    maxHeight: `calc(100% - ${theme.spacing(6.125)})`,
    overflowY: "auto",
  },
  scanCredentialStep: {
    paddingTop: "0rem",
    paddingBottom: theme.spacing(8),
  },
  list: {
    width: "100%",
    maxWidth: theme.spacing(60),
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  codeReader: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  stepper: {
    flexGrow: 0,
  },
  stepperHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  stepperHeaderInner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "none",
    maxWidth: theme.spacing(60),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  scanCredentialHeader: {
    marginBottom: theme.spacing(1),
  },
  entryChoiceButton: {
    display: "flex",
    flexDirection: "row",
    textTransform: "none",
    textAlign: "left",
    width: "100%",
    maxWidth: theme.spacing(60),
    height: "45%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey.main}`,
  },
  entryChoiceButtonIcon: {
    flex: 1,
    fontSize: theme.spacing(8),
  },
  entryChoiceButtonText: {
    flex: 2,
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(1),
    },
  },
  toggleButtonRoot: {
    margin: theme.spacing(1),
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  toggleButtonSelected: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  credentialResultWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: theme.spacing(1.75),
  },
  credentialResultTitle: {
    fontSize: theme.spacing(1.5),
  },
  credentialValid: {
    color: `${theme.palette.amano.warnings.good}`,
  },
  credentialScanning: {
    color: `${theme.palette.amano.warnings.warning}`,
  },
  credentialInvalid: {
    color: `${theme.palette.error.main}`,
  },
  selectOfferButton: {
    border: `1px solid ${theme.palette.grey.main}`,
    marginTop: theme.spacing(1),
  },
  totalCost: {
    width: "100%",
    maxWidth: theme.spacing(60),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.primary.contrastText}`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  transactionCancelButton: {
    marginTop: theme.spacing(4),
  },
  costBreakdownWrapper: {
    width: "100%",
    padding: theme.spacing(1),
    minHeight: theme.spacing(8),
    height: theme.spacing(16),
    maxWidth: theme.spacing(60),
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: theme.spacing(0.5),
    overflowY: "auto",
    overflowX: "hidden",
  },
  breakdownTicketID: {
    width: "100%",
    textAlign: "center",
  },
  breakdownText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  breakdownDiscounts: {
    marginTop: theme.spacing(0.5),
  },
  viewDiscounts: {
    width: "100%",
    maxWidth: theme.spacing(60),
    padding: theme.spacing(2),
    fontSize: theme.spacing(2.5),
    marginTop: "8px",
    marginBottom: "8px",
    border: `1px solid ${theme.palette.grey.main}`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  adjustFeeText: {
    width: "100%",
    maxWidth: theme.spacing(60),
    marginBottom: "8px"
  },
  adjustFeeCompleteButton: {
    width: "100%",
    maxWidth: theme.spacing(60),
    fontSize: theme.spacing(2.5),
    marginTop: "8px",
  }, 
  paymentTypeChoice: {
    flex: 1,
    width: "100%",
    maxWidth: theme.spacing(60),
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    alignItems: "stretch",
  },
  paymentChoiceButtonWrapper: {
    flex: 1,
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: theme.spacing(0.5),
    marginRight: "0rem",
  },
  paymentChoiceCash: {
    margin: theme.spacing(1),
    marginLeft: "0rem",
  },
  paymentChoiceButtonCredit: {
    width: "100%",
    height: "100%",
    fontSize: theme.spacing(2),
  },
  paymentChoiceButton: {
    flex: 1,
    fontSize: theme.spacing(2),
    border: `1px solid ${theme.palette.grey.main}`,
  },
  changeDue: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  cashAmountInput: {
    width: "100%",
    maxWidth: theme.spacing(60),
    marginBottom: theme.spacing(1),
  },
  completeButton: {
    width: "100%",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(2),
  },
  cashAmountButton: {
    width: "100%",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  printButton: {
    width: "100%",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(4),
  },
  summaryCompleteButton: {
    width: "100%",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(2),
  },

  ticketValidationRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "0 1 100%",
  },
  ticketInfo: {
    maxHeight: theme.spacing(7.5),
    margin: "0rem",
  },
  ticketIdText: {},
  validate: {
    width: "100%",
    maxWidth: theme.spacing(45),
    marginTop: theme.spacing(1),
    display: "inline-flex",
    justifyContent: "space-between",
  },
  validationDescriptionContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  appliedValidation: {
    maxWidth: "75%",
  },
  removeButton: {
    margin: theme.spacing(0.5),
    height: theme.spacing(5),
    flexBasis: theme.spacing(5.5),
    alignSelf: "flex-end",
  },
  applyButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    maxHeight: theme.spacing(5),
    alignSelf: "center",
    flexBasis: theme.spacing(5.5),
  },
  multiValDropdown: {
    flex: 1,
  },
  validations: {
    margin: theme.spacing(0.5),
  },
  appliedValidationlist: {
    width: "100%",
    maxWidth: theme.spacing(45),
    minHeight: theme.spacing(12),
    overflowY: "auto",
    overflowX: "hidden",
  },
  summaryButtonsWrapper: {
    width: "100%",
    maxWidth: theme.spacing(45),
  },
  summaryStep: {
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(6),
    },
  },
  valetFormContent: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  }, 
  buttonRight: {
    margin: theme.spacing(2),
    float: "right",
    marginRight: 0,
  }, 
  valetEntryTypeStep: {
    flex: 1,
    display: "flex",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    }
  },
  gridItem: {
    display: "flex",
  },
  valetEntryChoiceButton: {
    display: "flex",
    flex: 1,
    textTransform: "none",
    textAlign: "left",
    border: `1px solid ${theme.palette.grey.main}`,
  },
  valetEntryChoiceButtonText: {
    flex: 2,
    paddingRight: theme.spacing(1),
  }, 
  hideInMobile:{
    display:"flex",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  nonActionableButton: {
    pointerEvents:"none", 
    cursor: "default"
  }
}));

export default useStyles;
