import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import RateContainer from ".";
import { TextField, Button, Tooltip, Select, MenuItem, InputLabel, Checkbox, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import SingleSelectDropdown from "../Dropdowns/SingleSelect";
import useHasPermissions from "../../hooks/useHasPermissions";
import DurationEntry from "./Inputs/DurationEntry";
import clsx from "clsx";
import useComposerStyle from "./RateComposer.styles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { REPAYMENT_MODE } from "../../constants/index";
import DeleteDisabledToolTip from "./RatesListView/DeleteDisabledToolTip";

const RatesComposer = ({
  selectedRateID,
  handleSave,
  handleDelete,
  ratesArray,
  taxes
}) => {
  const history = useHistory();
  const classes = useComposerStyle();
  const { hasPermissions } = useHasPermissions();
  const AddRatePermission = hasPermissions(["rates.add"]);
  const EditRatePermission = hasPermissions(["rates.edit"]);
  const DeleteRatePermission = hasPermissions(["rates.delete"]);

  const [edited, setEditedState] = React.useState(false);
  const [selectedRate, setSelectedRate] = React.useState({});
  const [selectedRateName, setSelectedRateName] = React.useState("");
  const [selectedRateDescription, setSelectedRateDescription] = React.useState(
    ""
  );
  const [selectedLostFee, setSelectedLostFee] = React.useState(0);
  const [selectedEntryGrace, setSelectedEntryGrace] = React.useState(0);
  const [selectedEntryUpTo, setSelectedEntryUpTo] = React.useState(0);
  const [selectedTax, setSelectedTax] = React.useState();
  const [rateDisplay, setRateDisplay] = React.useState("");
  const [availableRates, setAvailableRates] = React.useState([]);
  const [selectedApplyValidationsOnZeroFee, setSelectedApplyValidationsOnZeroFee] = React.useState(false);

  const { taxes: taxFeatureFlag, rateRepaymentMode : repaymentFeatureFlag } = useFlags();
  
  const USE_RATE_STARTING_AT = { ACTIVATION: 0, EXPIRATION: 1 };
  const [prepaidUseRateMode, setPrepaidUseRateMode] = React.useState(USE_RATE_STARTING_AT.ACTIVATION);

  const [selectedRepaymentMode, setSelectedRepaymentMode] = React.useState(REPAYMENT_MODE.Restart);

  const newRate = {
    rateID: "00000000-0000-0000-0000-000000000000",
    entityID: "",
    name: "",

    description: "",
    rateApplications: [],
    rateDisplay: "",
    taxID: 0,
    rateSettings: {
      lostTicketFee: 0,
      applyValidationsOnZeroFee: false,
      prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
      repaymentMode : REPAYMENT_MODE.Restart
    }
  };

  useEffect(() => {
    setAvailableRates(JSON.parse(JSON.stringify(ratesArray)));
  }, [ratesArray]);

  useEffect(() => {
    if (!selectedRateID) {
      setSelectedRate({ ...newRate });
      return;
    }
    let changedRate = getRateElementByID(selectedRateID);
    changeSelectedRate(changedRate);
  }, [selectedRateID, availableRates]);

  const saveRate = (selectedRate, rateDisplay) => {
    handleSave(selectedRate, rateDisplay);
    setEdited(false);
    history.push("/rates");
  };

  function setEdited(state) {
    if (state == false) return setEditedState(false);
    if (edited == false) {
      return setEditedState(true);
    }
  }

  function getRateElementByID(rid, ar) {
    var i;
    if (ar == null) ar = availableRates;
    for (i = 0; i < ar.length; i++) {
      if (ar[i].rateID == rid) break;
    }
    if (i == ar.length) return null;
    return i;
  }

  function changeSelectedRate(i, ar) {
    if (i == null) return;
    if (ar == null || ar == undefined) ar = availableRates;
    setSelectedRate({ ...ar[i] });
    setSelectedRateName(ar[i].name);
    setSelectedRateDescription(ar[i].description);
    setSelectedLostFee(
      ar[i].rateSettings != null ? ar[i].rateSettings.lostTicketFee : 0
    );
    setSelectedApplyValidationsOnZeroFee(
      ar[i].rateSettings != null ? ar[i].rateSettings.applyValidationsOnZeroFee : false
    );
    setSelectedEntryUpTo(
      ar[i].rateSettings != null &&
        ar[i].rateSettings.prepaidSettings?.allowEntryUpTo
        ? ar[i].rateSettings.prepaidSettings?.allowEntryUpTo
        : 0
    );
    setSelectedEntryGrace(
      ar[i].rateSettings != null &&
        ar[i].rateSettings.prepaidSettings?.earlyEntryGrace
        ? ar[i].rateSettings.prepaidSettings?.earlyEntryGrace
        : 0
    );
    setRateDisplay(ar[i].rateDisplay);
    setSelectedTax(ar[i].taxID ?? 0);
    setPrepaidUseRateMode(
      ar[i].rateSettings != null &&
        ar[i].rateSettings.prepaidSettings?.useRateStartingAt
        ? ar[i].rateSettings.prepaidSettings?.useRateStartingAt
        : USE_RATE_STARTING_AT.ACTIVATION
    );
    setSelectedRepaymentMode(
      ar[i].rateSettings != null && ar[i].rateSettings.repaymentMode ? ar[i].rateSettings.repaymentMode : REPAYMENT_MODE.Restart
    );
  }

  function setname(e) {
    selectedRate.name = e.target.value;
    setSelectedRateName(e.target.value);
    setEdited(true);
  }

  function setdescription(e) {
    selectedRate.description = e.target.value;
    setSelectedRateDescription(e.target.value);
    setEdited(true);
  }

  function setlostfee(e) {
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: parseFloat(e.target.value),
        applyValidationsOnZeroFee: false,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else selectedRate.rateSettings.lostTicketFee = parseFloat(e.target.value);

    setSelectedLostFee(e.target.value);
    setEdited(true);
  }

  const setApplyValidationsOnZeroFee = (e) => {
    
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: 0,
        applyValidationsOnZeroFee: e.target.checked,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else {
      selectedRate.rateSettings.applyValidationsOnZeroFee = e.target.checked;
    }

    setSelectedApplyValidationsOnZeroFee(e.target.checked);
    setEdited(true);
  }

  function setentrygrace(name, value) {
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: 0,
        applyValidationsOnZeroFee: false,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: value, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else if (selectedRate.rateSettings.prepaidSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: selectedRate.rateSettings.lostTicketFee,
        applyValidationsOnZeroFee: selectedRate.rateSettings.applyValidationsOnZeroFee,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: value, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : selectedRate.rateSettings.repaymentMode ?? REPAYMENT_MODE.Restart
      };
    else selectedRate.rateSettings.prepaidSettings.earlyEntryGrace = value;

    setSelectedEntryGrace(value);
    setEdited(true);

    if (selectedEntryUpTo < value) {
      setentryupto(null, value);
    }
  }

  function setentryupto(name, value) {
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: 0,
        applyValidationsOnZeroFee: false,
        prepaidSettings: { allowEntryUpTo: value, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else if (selectedRate.rateSettings.prepaidSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: selectedRate.rateSettings.lostTicketFee,
        applyValidationsOnZeroFee: selectedRate.rateSettings.applyValidationsOnZeroFee,
        prepaidSettings: { allowEntryUpTo: value, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : selectedRate.rateSettings.repaymentMode ?? REPAYMENT_MODE.Restart
      };
    else selectedRate.rateSettings.prepaidSettings.allowEntryUpTo = value;

    setSelectedEntryUpTo(value);
    setEdited(true);
  }

  function setPCalcMode(e) {
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: 0,
        applyValidationsOnZeroFee: false,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: e.target.value},
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else if (selectedRate.rateSettings.prepaidSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: selectedRate.rateSettings.lostTicketFee,
        applyValidationsOnZeroFee: selectedRate.rateSettings.applyValidationsOnZeroFee,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: e.target.value },
        repaymentMode : selectedRate.rateSettings.repaymentMode ?? REPAYMENT_MODE.Restart
      };
    else selectedRate.rateSettings.prepaidSettings.useRateStartingAt = e.target.value;

    setPrepaidUseRateMode(e.target.value);
    setEdited(true);
  }

  function setRepaymentMode(repaymentMode) {
    if (selectedRate.rateSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: 0,
        applyValidationsOnZeroFee: false,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION},
        repaymentMode : REPAYMENT_MODE.Restart
      };
    else if (selectedRate.rateSettings.prepaidSettings == null)
      selectedRate.rateSettings = {
        lostTicketFee: selectedRate.rateSettings.lostTicketFee,        
        applyValidationsOnZeroFee: selectedRate.rateSettings.applyValidationsOnZeroFee,
        prepaidSettings: { allowEntryUpTo: 0, earlyEntryGrace: 0, useRateStartingAt: USE_RATE_STARTING_AT.ACTIVATION },
        repaymentMode : selectedRate.rateSettings.repaymentMode ?? REPAYMENT_MODE.Restart
      };
    else selectedRate.rateSettings.repaymentMode = repaymentMode;

    setSelectedRepaymentMode(repaymentMode);
    setEdited(true);
  }

  const shouldBeDisabled = () => {
    if (selectedRateID == newRate.rateID) {
      return !AddRatePermission;
    } else {
      return !EditRatePermission;
    }
  };

  const handleRateDisplayChange = (display, isFocused) => {
    if(isFocused) {
      setRateDisplay(display);
      setEdited(true);
    }
  };

  const selectedTaxChanged = tax => {
    selectedRate.taxID = tax;
    setSelectedTax(tax);
    setEdited(true);
  };

  const deleteDisabled = selectedRate?.assignedEntities?.length > 0 || selectedRate?.assignedOffers?.length > 0;

  return (
    <>
      <div className={classes.topRoot}>
        <div className={clsx(classes.flexRow, classes.composerRowPadding)}>
          <TextField
            label="Rate name"
            type="text"
            name="name"
            value={selectedRateName}
            className={clsx("rateNameTextField", classes.rateName)}
            onChange={setname}
            title=""
            disabled={shouldBeDisabled()}
            InputProps={{
              readOnly: Boolean(shouldBeDisabled()),
              "aria-readonly": Boolean(shouldBeDisabled()),
              disabled: Boolean(shouldBeDisabled())
            }}
            inputProps={{
              "data-testid": "rate-name"
            }}
          />
          <TextField
            label="Description"
            type="text"
            name="desc"
            value={selectedRateDescription}
            className={clsx(
              "rateDescriptionTextField",
              classes.rateDescription
            )}
            onChange={setdescription}
            title=""
            disabled={shouldBeDisabled()}
            InputProps={{
              readOnly: Boolean(shouldBeDisabled()),
              "aria-readonly": Boolean(shouldBeDisabled()),
              disabled: Boolean(shouldBeDisabled())
            }}
          />
        </div>
        <div className={clsx(classes.flexRow, classes.composerRowPadding)}>
          <TextField
            label="Lost Fee"
            type="number"
            inputProps={{ min: 0, step: 0.01 }}
            className={clsx("lostFeeTextField", classes.lostFee)}
            name="lost"
            value={parseFloat(selectedLostFee)}
            onChange={setlostfee}
            title=""
            InputProps={{
              readOnly: Boolean(shouldBeDisabled()),
              "aria-readonly": Boolean(shouldBeDisabled()),
              disabled: Boolean(shouldBeDisabled())
            }}
          />
          {taxFeatureFlag === true && (
            <SingleSelectDropdown
              data-id="tax-dropdown"
              className={classes.taxDropdown}
              name="Taxes"
              variant="standard"
              defaultSelected={0}
              value={selectedTax ?? 0}
              options={
                taxes?.map(tax => {
                  return {
                    value: tax.taxID,
                    name: tax.taxName
                  };
                }) ?? []
              }
              onSelect={selectedTaxChanged}
            />
          )}
          {repaymentFeatureFlag === true && (
            <Tooltip
              data-id="repayment-tooltip"
              placement="top"
              title={selectedRepaymentMode == REPAYMENT_MODE.Restart
                ? "Repayment is calculated from the start of the rate."
                : "Repayment is calculated from where the parker had left off after the most recent payment."}
            >
              <div>
                <SingleSelectDropdown
                  data-id="repayment-dropdown"
                  className={classes.repaymentDropdown}
                  name="Repayment Mode"
                  variant="standard"
                  defaultSelected={REPAYMENT_MODE.Restart}
                  value={selectedRepaymentMode}
                  options={
                    Object.entries(REPAYMENT_MODE)?.map((elment) => {
                      return {
                        value: elment[1],
                        name: elment[0]
                      }
                    })
                  }
                  onSelect={setRepaymentMode}
                />
              </div>
            </Tooltip>
          )}          
        </div>
        <div className={clsx(classes.flexRow)}>
          <Tooltip
            placement="top-end"
            title="Allows prepaid passholders to enter prior to their prepaid pass start time.  Overage charges may apply upon exit unless Prepaid Entry Grace Time equals this value"
          >
            <DurationEntry
              label="Prepaid Entry Up To"
              name="earlyentryupto"
              value={selectedEntryUpTo}
              onChange={setentryupto}
              disabled={shouldBeDisabled()}
            />
          </Tooltip>
          <Tooltip
            placement="top-end"
            title="Allows prepaid passholders to enter prior to their prepaid pass start time without incurring an overage charge upon exit"
          >
            <DurationEntry
              label="Prepaid Entry Grace Time"
              name="earlygracetime"
              value={selectedEntryGrace}
              onChange={setentrygrace}
              disabled={shouldBeDisabled()}
            />
          </Tooltip>
          <Tooltip
            data-id="useratestartingat-tooltip"
            placement="top"
            title={prepaidUseRateMode == USE_RATE_STARTING_AT.ACTIVATION
              ? "Calculates the prepaid overage rate by taking the early entrance time and late exit time and uses where the rate structure is based on the activation date of the prepaid pass."
              : "Calculates the prepaid overage rate by taking the early entrance time and late exit time and uses where the rate structure is on expiration time of the prepaid pass."}
          >
            <div >
              <InputLabel shrink>Overage Rate Calculation</InputLabel>
              <Select
                className={classes.rateStartModeDropdown}
                data-id="useratestartingat-dropdown"
                autoWidth
                value={prepaidUseRateMode}
                onChange={setPCalcMode}
              >
                <MenuItem value={USE_RATE_STARTING_AT.ACTIVATION}>Entrance Time</MenuItem>
                <MenuItem value={USE_RATE_STARTING_AT.EXPIRATION}>Expiration Time</MenuItem>
              </Select>
            </div>
          </Tooltip>
        </div>
        <div className={clsx(classes.flexRow)}>
          <Tooltip          
          placement="top"
          title="Allow surcharge online validations to be applied to $0 transactions, does not apply to grace transactions"          
          data-id="apply-validations-on-zero-fee-tooltip"
          >
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedApplyValidationsOnZeroFee}
                onChange={setApplyValidationsOnZeroFee}            
                name="applyValidationsOnZeroFee"
                className={clsx("applyValidationsOnZeroFeeCheckbox")}
                data-id="applyValidationsOnZeroFeeCheckbox"
              />
            }
            label="Surcharge Validations for $0 Transactions"
          />
          </Tooltip>
        </div>
      </div>

      <RateContainer
        key="rateeditor"
        rateStructure={selectedRate.rateApplications}
        onChange={() => {
          setEdited(true);
        }}
        rateID={selectedRateID}
        onDisplayChange={handleRateDisplayChange}
        rateDisplay={selectedRate.rateDisplay}
        tax={selectedTax}
      />
      {edited && (AddRatePermission || EditRatePermission) && (
        <Button
          key="savebutton"
          className={clsx("saveRateBtn", classes.actionButton)}
          data-id="save-button"
          variant="contained"
          color="secondary"
          onClick={() => saveRate(selectedRate, rateDisplay)}
        >
          Save
        </Button>
      )}
      {DeleteRatePermission && selectedRate.rateID !== newRate.rateID && (
        <>
          {deleteDisabled ? (
            <Tooltip
              title={<DeleteDisabledToolTip rate={selectedRate}></DeleteDisabledToolTip>}
              aria-label="delete"
              placement="top"
              arrow>
              <span>
                <Button
                  disabled={true}
                  data-id="deleteBtn"
                  className={clsx("deleteRateBtn", classes.actionButton)}
                  startIcon={<DeleteIcon/>}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(selectedRate.rateID)}>
                Delete
                </Button>
             </span>
            </Tooltip>
          ) : (
            <Button
              data-id="deleteBtn"
              className={clsx("deleteRateBtn", classes.actionButton)}
              startIcon={<DeleteIcon/>}
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(selectedRate.rateID)}
            >
              Delete
            </Button>
          )}
        </>
      )}
      <Button
        data-id="cancelBtn"
        className={clsx("cancelBtn")}
        startIcon={<CancelIcon />}
        variant="contained"
        onClick={() => history.push(`/rates`)}
      >
        Cancel
      </Button>
    </>
  );
};

RatesComposer.defaultProps = {
  selectedRateID: "",
  handleSave: () => {},
  handleDelete: () => {},
  ratesArray: [],
  taxes: []
};

RatesComposer.propTypes = {
  selectedRateID: PropTypes.string,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
  ratesArray: PropTypes.array,
  taxes: PropTypes.array
};

export default RatesComposer;
