import React, { useRef, useEffect, useState, useCallback } from "react";
import CardCarrier from "./CardCarrier";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";

/* constructor for card */
function Card(data, callbacks) {
  this.RefreshData = function(data) {
    this.data = data;
    this.reactElement = React.createElement(CardCarrier, {
      key: `cardcarrierreact_${data.entityid}`,
      card: this,
      "data-testid": "tetsting",
    });
  };
  this.data = data;
  this.size = { x: 0, y: 0 };
  this.position = { x: 0, y: 0 };
  this.callbacks = callbacks || {};
  this.reactElement = React.createElement(CardCarrier, {
    key: `cardcarrierreact_${data.entityid}`,
    card: this,
    "data-testid": "tetsting",
  });
  return this;
}

/*
  given an array and a data stucture
  generates card objects along with all the child cards
*/
function GenerateCardTree(tree, data, callbacks, root) {
  var toplevel = false;
  if (root == undefined) {
    root = tree;
    toplevel = true;
  }
  for (var i = 0; i < data.length; i++) {
    var card;
    card = TreeGetCardByID(root, data[i].entityid);
    if (card == null) {
      card = new Card(data[i], callbacks);
      card.children = [];
      card.center = { x: 0, y: 0 };
      card.data.toplevel = toplevel;
      if (callbacks.isEnabled(card.data.entitytype))
        tree.push(card);
    } else if (card.parent == null) {
      var index = root.indexOf(card);
      if (index != -1) {
        root.splice(index, 1);
        tree.push(card);
      }
      card.data.toplevel =
        TreeGetCardByID(root, data[i].parententityid) == null;
    } else {
      card.data.toplevel =
        TreeGetCardByID(root, data[i].parententityid) == null;
    }
    for (var j = card.children.length; j--; ) {
      var found = false;
      if (data[i].haschildren && data[i].children != null) {
        for (var k = 0; k < data[i].children.length; k++) {
          if (data[i].children[k].entityid == card.children[j].data.entityid) {
            found = true;
            break;
          }
        }
      } else if (data[i].haschildren && data[i].children == null) {
        found = true;
      }
      if (!found) {
        card.children.splice(j, 1);
      }
    }
    if (data[i].children)
      GenerateCardTree(card.children, data[i].children, callbacks, root);
    card.RefreshData(data[i]);
  }
}

function TreeGetCardByID(tree, entityid) {
  for (var i = 0; i < tree.length; i++) {
    if (tree[i].data.entityid == entityid) return tree[i];
    if (tree[i].children) {
      var card = TreeGetCardByID(tree[i].children, entityid);
      if (card) return card;
    }
  }
  return null;
}

/*
  given a layer array, a card tree, and optionally depth and parent
  populate the layers based on the given tree
*/
function GenerateLayers(layers, tree, depth, parent) {
  if (depth == null) {
    layers.splice(0, layers.length);
    depth = 0;
  }
  for (var i = 0; i < tree.length; i++) {
    if (layers[depth] == null) layers[depth] = [];
    if (tree[i].parent == null) tree[i].parent = parent;
    layers[depth].push(tree[i]);
    GenerateLayers(layers, tree[i].children, depth + 1, tree[i]);
  }
}

/*
  given a layer and a parent card
  return the horizontal midpoint of all the children from that parent in that layer
  null if no children from this parent in the given layer
*/
function GetChildrensMidpoint(layer, parent) {
  if (layer == null) return null;
  var mids = [];
  for (var i = layer.length; i--; ) {
    if (layer[i].parent == parent) {
      mids.push(layer[i].position.x + layer[i].size.x / 2);
    }
  }
  if (mids.length == 0) return null;
  return mids.reduce((prev, curr) => (curr += prev)) / mids.length;
}

/*
  given the layeredCards array,
  generates the tree-layout for the cards
*/
function PositionCards(layeredCards) {
  PositionCardsHorizontally(layeredCards);
  PositionCardsVertically(layeredCards);
}

function PositionCardsHorizontally(layeredCards) {
  for (var i = layeredCards.length; i--; ) {
    var nextAvailablePosition = 0;
    for (var k = 0; k < layeredCards[i].length; k++) {
      var childrenMidpoint = GetChildrensMidpoint(
        layeredCards[i + 1],
        layeredCards[i][k]
      );
      if (childrenMidpoint) {
        layeredCards[i][k].position.x =
          childrenMidpoint - layeredCards[i][k].size.x / 2;
        if (layeredCards[i][k].position.x < nextAvailablePosition) {
          ShiftCardLayer(
            layeredCards[i],
            nextAvailablePosition - layeredCards[i][k].position.x,
            k
          );
        }
      } else {
        layeredCards[i][k].position.x = nextAvailablePosition;
      }
      nextAvailablePosition =
        layeredCards[i][k].position.x +
        layeredCards[i][k].size.x +
        minHorizontalSpacing;
    }
  }
}

/* shift a card layer by a given offset starting at index (along with kids) */
function ShiftCardLayer(layer, offset, index) {
  for (var i = index; i < layer.length; i++) {
    ShiftCardTree(layer[i], { x: offset, y: 0 });
  }
}

/* shift a card and children by the given offset */
function ShiftCardTree(card, offset) {
  card.position.x += offset.x;
  card.position.y += offset.y;
  for (var i = card.children.length; i--; )
    ShiftCardTree(card.children[i], offset);
}

/* */
function PositionCardsVertically(layeredCards) {
  var nextAvailableHeight = 0;
  for (var i = 0; i < layeredCards.length; i++) {
    var maximumLayerHeight = 0;
    for (var k = 0; k < layeredCards[i].length; k++) {
      if (layeredCards[i][k].size.y > maximumLayerHeight)
        maximumLayerHeight = layeredCards[i][k].size.y;
      layeredCards[i][k].position.y = nextAvailableHeight;
    }
    nextAvailableHeight += maximumLayerHeight + minVerticalSpacing;
  }
}

/*
  given a card tree
  generates or update the lines between cards
*/
function ConnectTree(cardTree) {
  for (var i = cardTree.length; i--; ) {
    if (cardTree[i].lines == null) cardTree[i].lines = [];
    if (cardTree[i].children.length == 0) continue;
    // find position for the lines
    var parentDownspout = {
      x: cardTree[i].position.x + cardTree[i].size.x / 2,
      y: cardTree[i].position.y + cardTree[i].size.y,
    };
    var childrenMidX = [];
    var childrenTopY = cardTree[i].children[0].position.y;
    for (var k = cardTree[i].children.length; k--; )
      childrenMidX.push(
        cardTree[i].children[k].position.x + cardTree[i].children[k].size.x / 2
      );
    var childrenMinX = Math.min.apply(null, childrenMidX);
    var childrenMaxX = Math.max.apply(null, childrenMidX);
    var horizontalConnectorY = (childrenTopY + parentDownspout.y) / 2;

    // create lines
    var lines = [];
    lines.push(
      horizontalLine(childrenMinX, childrenMaxX, horizontalConnectorY)
    ); // vertical drop from this component down
    lines.push(
      verticalLine(parentDownspout.x, parentDownspout.y, horizontalConnectorY)
    ); // horizontal bus bar
    for (
      var j = childrenMidX.length;
      j--; // vertical drops from bus bar to the children

    )
      lines.push(
        verticalLine(
          childrenMidX[j],
          horizontalConnectorY,
          cardTree[i].children[j].position.y
        )
      );
    AttachLinesToCard(lines, cardTree[i]);
    ConnectTree(cardTree[i].children); // connect children
  }
}

function verticalLine(x, y1, y2) {
  return { x1: x, x2: x, y1: y1, y2: y2 };
}

function horizontalLine(x1, x2, y) {
  return { x1: x1, x2: x2, y1: y, y2: y };
}

/* update and attach lines to a card */
function AttachLinesToCard(lines, card, useRef) {
  for (var i = 0; i < lines.length; i++) {
    if (card.lines[i] == null) {
      card.lines[i] = lines[i];
      card.lines[i].ref = null;
      continue;
    }
    card.lines[i].x1 = lines[i].x1;
    card.lines[i].x2 = lines[i].x2;
    card.lines[i].y1 = lines[i].y1;
    card.lines[i].y2 = lines[i].y2;
  }
}

function GetCardReactComponentsFromTree(cardTree) {
  var flat = [];
  for (var i = cardTree.length; i--; ) {
    flat.push(cardTree[i].reactElement);
    flat.push(...GetCardReactComponentsFromTree(cardTree[i].children));
  }
  return flat;
}

/*  */
function GetTreeBoundingBoxes(cardTree, boxes) {
  if (boxes == null) boxes = { x1: [], y1: [], x2: [], y2: [] };
  for (var i = cardTree.length; i--; ) {
    var x1 = cardTree[i].position.x;
    var y1 = cardTree[i].position.y;
    var x2 = x1 + cardTree[i].size.x;
    var y2 = y1 + cardTree[i].size.y;
    boxes.x1.push(x1);
    boxes.y1.push(y1);
    boxes.x2.push(x2);
    boxes.y2.push(y2);
    GetTreeBoundingBoxes(cardTree[i].children, boxes);
  }
  return boxes;
}

/* given a tree, returns the bounding box */
function GetTreeBoundingBox(cardTree) {
  var boundingBoxes = GetTreeBoundingBoxes(cardTree);
  var treeBoundingBox = {
    x1: Math.min.apply(null, boundingBoxes.x1),
    y1: Math.min.apply(null, boundingBoxes.y1),
    x2: Math.max.apply(null, boundingBoxes.x2),
    y2: Math.max.apply(null, boundingBoxes.y2),
  };
  return treeBoundingBox;
}

/*
  given a formed tree, finds bounding box and centers it on field
*/
function CenterCards(cardTree) {
  //return;
  var box = GetTreeBoundingBox(cardTree);
  var boxSize = { x: box.x2 - box.x1, y: box.y2 - box.y1 };
  var offset = {
    x: (fieldWidth - boxSize.x) / 2,
    y: (fieldHeight - boxSize.y) / 2,
  };
  for (var i = cardTree.length; i--; ) ShiftCardTree(cardTree[i], offset);
}

function RepositionCards(cardTree) {
  for (var i = cardTree.length; i--; ) {
    if (!cardTree[i].functions) return;
    cardTree[i].functions.RefreshCarrier();
    RepositionCards(cardTree[i].children);
  }
}

function SetOuterContainerSize(ele) {
  // getting overall available height
  ele.style.height = "";
  var topThing = ele.parentElement.parentElement.firstChild.scrollHeight;
  var bottomThing =
    ele.parentElement.parentElement.parentElement.lastChild.scrollHeight;
  var overallHeight =
    ele.parentElement.parentElement.parentElement.clientHeight;
  var height = overallHeight - (topThing + bottomThing);
  height -= 64; // ugh padding cannot be pulled initially
  ele.style.height = height + "px";
  return [ele.clientWidth, ele.clientHeight];
}

/* make lines in the substrate */
function UpdateLines(cardTree, substrate) {
  if (substrate == null) return;
  for (var i = cardTree.length; i--; ) {
    if (!cardTree[i].lines) continue;
    for (var j = cardTree[i].lines.length; j--; ) {
      UpdateLine(cardTree[i].lines[j], substrate);
    }
    UpdateLines(cardTree[i].children, substrate);
  }
}

function UpdateLine(line, substrate) {
  if (line.ref == null) {
    line.ref = document.createElementNS("http://www.w3.org/2000/svg", "line");
    substrate.append(line.ref);
  }
  line.ref.setAttribute("x1", line.x1);
  line.ref.setAttribute("y1", line.y1);
  line.ref.setAttribute("x2", line.x2);
  line.ref.setAttribute("y2", line.y2);
  line.ref.setAttribute("stroke", "rgb(64,64,64)");
}

const minHorizontalSpacing = 20;
const minVerticalSpacing = 80;
const scaleClampMin = 0.25;
const scaleClampMax = 2;
const fieldWidth = 30000;
const fieldHeight = 30000;

const outerContainerStyles = {
  position: "relative",
  overflow: "hidden",
};

const innerContainerStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  height: fieldHeight,
  width: fieldWidth,
};

const backgroundStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  transition: "0.2s",
  height: fieldHeight,
  width: fieldWidth,
};

const OrgChart = (props) => {

  const { contractNest } = useFlags();
  const contractNestFeature = useFeatureFlag("Contract Nest");

  const isEnabledByEntityType = useCallback((typeID) => {
    switch (typeID.toUpperCase()) {
      case 'AREA':
        return contractNestFeature && contractNest;
    }
    return true;
  }, [contractNestFeature, contractNest]);

  function RestructureTree() {
    window.clearTimeout(restructureDebounce);
    restructureDebounce = window.setTimeout(RestructureTreeInner, 10);
  }

  function RestructureTreeInner() {
    GenerateLayers(state.layeredCards, state.cardTree);
    PositionCards(state.layeredCards);
    CenterCards(state.cardTree, { x: fieldWidth, y: fieldHeight });
    ConnectTree(state.cardTree);
    RepositionCards(state.cardTree);
    UpdateLines(state.cardTree, background);
  }

  function UpdateOuterContainerSize() {
    SetOuterContainerSize(outerContainer);
  }

  function CenterPan() {
    if (!outerContainer) return;
    SetPan({
      x: fieldWidth / 2 - outerContainer.offsetWidth / 2,
      y: fieldHeight / 2 - outerContainer.offsetHeight / 2,
    });
  }

  function SetPan(pos) {
    state.currentPan.x = pos.x;
    state.currentPan.y = pos.y;
    outerContainer.scrollTop = pos.y;
    outerContainer.scrollLeft = pos.x;
  }

  /* adjust scale while keeping an origin point in the same relative place */
  function ScaleByDeltaFactor(delta, origin) {
    var oldScale = state.currentScale;
    var newScale = state.currentScale - state.currentScale * delta * 0.001;
    newScale = Math.min(scaleClampMax, Math.max(scaleClampMin, newScale));
    state.currentScale = newScale;
    innerContainer.style.transform = `scale(${state.currentScale})`;
    // offsetOrigin is the relative position of the origin point before anything happened
    var offsetOrigin = {
      x:
        origin.x -
        outerContainer.offsetLeft +
        state.currentPan.x -
        fieldWidth / 2,
      y:
        origin.y -
        outerContainer.offsetTop +
        state.currentPan.y -
        fieldHeight / 2,
    };
    // newOffsetOrigin is where the origin point will be after the scaling happens
    var newOffsetOrigin = {
      x: (offsetOrigin.x / oldScale) * newScale,
      y: (offsetOrigin.y / oldScale) * newScale,
    };
    // adjust pan to make sure the old offset origin remains in the same place after the scaling
    SetPan({
      x: state.currentPan.x + newOffsetOrigin.x - offsetOrigin.x,
      y: state.currentPan.y + newOffsetOrigin.y - offsetOrigin.y,
    });
  }

  function MouseDown(event) {
    //if(event.target!=innerContainer) return;
    interact.origin.x = event.pageX;
    interact.origin.y = event.pageY;
    interact.active = true;
  }

  function MouseUp(event) {
    interact.active = false;
  }

  function MouseMove(event) {
    if (!interact.active) return;
    if (!event.buttons) return;
    SetPan({
      x: state.currentPan.x + interact.origin.x - event.pageX,
      y: state.currentPan.y + interact.origin.y - event.pageY,
    });
    interact.origin.x = event.pageX;
    interact.origin.y = event.pageY;
  }

  function MouseWheel(event) {
    ScaleByDeltaFactor(event.deltaY > 0 ? 100 : -100, {
      x: event.clientX,
      y: event.clientY,
    });
  }

  var interact = {
    origin: { x: 0, y: 0 },
    active: false,
  };

  const [state, setState] = useState({
    cardTree: [],
    layeredCards: [],
    currentPan: { x: 0, y: 0 },
    currentScale: 1.0,
    initialized: false,
  });

  var innerContainer = null;
  var outerContainer = null;
  var background = null;
  var restructureDebounce = null;

  const outerContainerRef = useRef(null);
  const innerContainerRef = useRef(null);
  const backgroundRef = useRef(null);

  GenerateCardTree(state.cardTree, props.data, {
    onResize: RestructureTree,
    loadEntity: props.loadEntity,
    onDelete: props.onDelete,
    isEnabled: isEnabledByEntityType
  });

  useEffect(() => {
    innerContainer = innerContainerRef.current;
    outerContainer = outerContainerRef.current;
    background = backgroundRef.current;
    UpdateOuterContainerSize();
    if (!state.initialized) {
      state.initialized = true;
      CenterPan();
      window.addEventListener("resize", UpdateOuterContainerSize);
      innerContainer.addEventListener("mouseup", MouseUp);
      innerContainer.addEventListener("mousedown", MouseDown);
      innerContainer.addEventListener("mousemove", MouseMove);
      innerContainer.addEventListener("wheel", MouseWheel);
    }
    return () => {
      window.removeEventListener("resize", UpdateOuterContainerSize);
    };
  });

  var treeComponentsRD = [
    React.createElement("svg", {
      key: "svg",
      ref: backgroundRef,
      style: backgroundStyles,
      "data-testid": "tree-components-rd",
    }),
  ];
  treeComponentsRD.push(...GetCardReactComponentsFromTree(state.cardTree));
  var innerContainerRD = React.createElement(
    "div",
    { key: "ic", ref: innerContainerRef, style: innerContainerStyles, "data-testid": "inner-container" },
    treeComponentsRD
  );
  var outerContainerRD= React.createElement(
    "div",
    { key: "oc", ref: outerContainerRef, style: outerContainerStyles, "data-testid": "outer-container" },
    innerContainerRD
  );
  return outerContainerRD;
};

export default OrgChart;
