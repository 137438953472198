import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import {
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";

/* Style */
import clsx from "clsx";

/* State */
import { useSelector, useDispatch } from "react-redux";
import {
  addPayment,
  endProcessingCashPayment,
  processingCashPayment,
} from "../../../state/slices/shiftSession/shiftSession";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useStateRef from "../../../hooks/useStateRef";

/* Utilities */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import usePos, { ResponseTypes } from "../Utilities/usePos";

/* Constants */
import { PAYMENT_TYPES } from "../../../constants";
import { NavConfirmation } from "../../UserConfirmation";

const defaultLoadingState = { isLoading: false, message: "" };

const CashieredDevicePayCash = ({ classes, onComplete }) => {
  const [cashAmount, setCashAmount] = useStateRef(0);
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const paymentInformation = useSelector(
    (state) => state.shiftSession.transaction
  );
  const { facilityID } = useCurrentFacility();
  const currentUser = useCurrentUser();
  const { processCashPayment } = usePos({
    facilityID,
    cashierID: currentUser.UserID,
    onTransactionChanged: handleTransactionChanged,
  });
  const [loading, setLoading] = useState(defaultLoadingState);
  const [canShowPrompt, setCanShowPrompt] = useState(false);
  const hydratedPaymentStatus = useSelector(
    (state) => state.shiftSession.transaction.status
  );
  const isZeroFee = paymentInformation.totalCost === 0;

  useEffect(() => {
    if (!hydratedPaymentStatus) {
      if (isZeroFee) {
        onCompleteCashPayment();
      } 
      return;
    }
    if (
      hydratedPaymentStatus === ResponseTypes.AwaitingPaymentCompleted ||
      hydratedPaymentStatus === ResponseTypes.InProgress
    ) {
      setLoading({ isLoading: true, message: "Processing Payment..." });
      setCanShowPrompt(true);
    } 
  }, [hydratedPaymentStatus]);

  async function handleTransactionChanged(transaction) {
    if (transaction.Status === ResponseTypes.Failed) {
      setCanShowPrompt(false);
      dispatch(endProcessingCashPayment());
      enqueueSnackbar("Failed to process cash payment. Please retry.", {
        variant: "error",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      setLoading(defaultLoadingState);
    }
    if (transaction.Status === ResponseTypes.Complete) {
      setCanShowPrompt(false);
      if (!isZeroFee) {
        dispatch(addPayment({ total: cashAmount.current, paymentType: PAYMENT_TYPES.Cash }));
      }
      dispatch(endProcessingCashPayment());
      setTimeout(() => {
        // allowing the prompt flag to toggle
        onComplete();
      }, 100);
    }
  }

  const onCompleteCashPayment = async () => {
    try {
      setCashAmount(parseFloat(cashAmount.current).toFixed(2));
      setCanShowPrompt(true);
      setLoading({ isLoading: true, message: "Processing Payment..." });
      await processCashPayment(
        paymentInformation.totalCost,
        cashAmount.current
      );
      dispatch(processingCashPayment());
    } catch (error) {
      setCanShowPrompt(false);
      setLoading(defaultLoadingState);
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small.toString(),
        tag: "UnableToProcessPayment",
      });
    }
  };

  return (
    <div className={classes.step}>
      {loading.isLoading && (
        <>
          <NavConfirmation
            when={canShowPrompt}
            title="Warning!"
            message="Please complete your transaction before leaving the page."
            buttons={[
              {
                text: "Ok",
                attribute: "cash-navigation-ok-btn",
                state: false,
                color: "primary",
                order: 0,
              },
            ]}
          />
          <CircularProgress />
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="circular-progress-loader"
          >
            {loading.message}
          </Typography>
        </>
      )}
      {!loading.isLoading && (
        <>
          <Typography
            variant="h4"
            component="div"
            align="center"
            className={classes.totalCost}
            data-testid="totalCost"
          >
            {`$${paymentInformation.totalCost?.toFixed(2)}`}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            className={classes.changeDue}
            data-testid="changeDue"
          >
            <strong>Change Due</strong>
            {`: $${Math.max(
              0,
              cashAmount.current - paymentInformation.totalCost
            )?.toFixed(2)}`}
          </Typography>
          <TextField
            className={clsx("cash-amount-input", classes.cashAmountInput)}
            variant="outlined"
            inputProps={{
              type: "number",
              min: "0",
              step: "0.01",
              pattern: "[0-9]*",
              inputMode: "numeric",
              "data-testid": "cash-amount-text-field",
            }}
            value={cashAmount.current}
            onChange={(event) => setCashAmount(event.target.value)}
          />
          <Button
            onClick={() => setCashAmount(100)}
            data-testid="cashAmount100"
            data-value="100"
            className={clsx(classes.cashAmountButton, "cash-amount")}
            variant="outlined"
          >
            $100
          </Button>
          <Button
            onClick={() => setCashAmount(50)}
            data-testid="cashAmount50"
            data-value="50"
            className={clsx(classes.cashAmountButton, "cash-amount")}
            variant="outlined"
          >
            $50
          </Button>
          <Button
            onClick={() => setCashAmount(20)}
            data-testid="cashAmount20"
            data-value="20"
            className={clsx(classes.cashAmountButton, "cash-amount")}
            variant="outlined"
          >
            $20
          </Button>
          <Button
            onClick={onCompleteCashPayment}
            data-testid="completeCashPayment"
            disabled={paymentInformation.totalCost > cashAmount.current}
            className={classes.completeButton}
            variant="contained"
            color="primary"
          >
            Complete
          </Button>
        </>
      )}
    </div>
  );
};

CashieredDevicePayCash.defaultProps = {
  onComplete: () => {},
};

CashieredDevicePayCash.propTypes = {
  onComplete: PropTypes.func,
};

export default CashieredDevicePayCash;
