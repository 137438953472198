import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Divider, TextField, Tooltip, Select, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";

import SelectableChip from "../../SelectableChip";
import { useStyles } from "./ValetInventorySearch.styles";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

const ValetInventorySearch = ({ onSearch, loading }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContext, setSelectedContext] = useState(0);
  const [selectedAge, setSelectedAge] = useState(1);
  const { timeZone } = useCurrentFacilityTimezone();

  const handleSearch = ({ searchTerm = "", selectedAge = 0, selectedContext = 0 }) => {
    let date;
    
    switch (selectedAge) {
      case 0:
        date = null;
        break;
      case 1:
        date = moment().tz(timeZone).startOf('day');
        break;
      case 2:
        date = moment().tz(timeZone).startOf('day').subtract(1, 'week');
        break;
      case 3:
        date = moment().tz(timeZone).startOf('day').subtract(1, 'month');
        break;
      case 4:
        date = moment().tz(timeZone).startOf('day').subtract(1, 'year');
        break;
      default:
        date = null;
    }

    if (date) {
      date = date.tz('UTC').format("MM/DD/YYYY hh:mm:ss A").toString();
    }

    onSearch({
      date: date,
      searchTerm: searchTerm,
      selectedContext: selectedContext,
      selectedAge: selectedAge
    });
  };

  const setSearch = () => {
    handleSearch({
      searchTerm: searchTerm,
      selectedAge: selectedAge,
      selectedContext: selectedContext
    });
  };

  const setAgeSearch = (Age) => {
    setSelectedAge(Age);
    handleSearch({
      searchTerm: "",
      selectedAge: Age,
      selectedContext: 0
    });
  };

  return (
    <>
      <Grid container item xs={12} md={6}>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "type-container"])} >
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["type-title"])}>Type</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <SelectableChip
              text="Ticket"
              className={clsx(["type-option", "ticket"])}
              variant="primary"
              selected={true}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "age-container"])} >
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["age-title"])}>Age</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <SelectableChip
              text="All"
              size="small"
              variant="secondary"
              onClick={() => setAgeSearch(0)}
              selected={selectedAge === 0}
              title="All entries, regardless of age"
            />
            <Divider orientation="vertical" flexItem />
            <SelectableChip
              text="Today"
              size="small"
              variant="secondary"
              onClick={() => setAgeSearch(1)}
              selected={selectedAge === 1}
              title="All entries of Today"
            />
            <SelectableChip
              text="A Week"
              size="small"
              variant="secondary"
              onClick={() => setAgeSearch(2)}
              selected={selectedAge === 2}
              title="All entries of a week"
            />
            <SelectableChip
              text="A Month"
              size="small"
              variant="secondary"
              onClick={() => setAgeSearch(3)}
              selected={selectedAge === 3}
              title="All entries of a month"
            />
            <SelectableChip
              text="A Year"
              size="small"
              variant="secondary"
              onClick={() => setAgeSearch(4)}
              selected={selectedAge === 4}
              title="All entries of a year"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} md={6} className={clsx(["search-params-container"])} >
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-criteria-container"])} >
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["search-criteria-title"])}>Find</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
          <Tooltip 
              placement="top-end" 
              title={
                selectedContext === 0 || selectedContext === 2
                  ? "Minimum 3 Characters" 
                  : selectedContext === 1 
                  ? "Minimum 2 Characters"
                  : "Minimum 2 Characters"
              }
            >
              <TextField
                className={clsx([classes.notFatInput, "search-term"])}
                id="search-term"
                size="small"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => { setSearchTerm(e.target.value); }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-filter-container"])} >
          <Grid item xs={2} md={2} >
            <Typography className={clsx(["search-filter-title"])}>In</Typography>
          </Grid>
          <Grid item xs={10} md={10} className={clsx([])} >
            <div className={classes.filterType}>
              <Select
                className={clsx([classes.notFatSelect, "filter-type"])}
                variant="outlined"
                size="small"
                id="filter-type"
                data-testid="filter-select"
                value={selectedContext}
                onChange={(e) => { setSelectedContext(e.target.value); }}
              >
                <MenuItem data-value={0} className={clsx(["filter-type-option", "Ticket-Number"])} value={0}>Ticket Number</MenuItem>
                <MenuItem data-value={1} className={clsx(["filter-type-option", "names"])} value={1}>Customer Name</MenuItem>
                <MenuItem data-value={2} className={clsx(["filter-type-option", "license-plates"])} value={2}>License Plates</MenuItem>
              </Select>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} className={clsx([classes.searchButtonRow])} >
        <Button
          disabled={loading || 
            (selectedContext === 0 && searchTerm.length < 3) || 
            (selectedContext === 1 && searchTerm.length < 2) || 
            (selectedContext === 2 && searchTerm.length < 3)}
          color="primary"
          className={clsx(["button", "search", classes.searchButton])}
          variant="contained"
          onClick={() => setSearch()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
};

export default ValetInventorySearch;