import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarBuilding as FacilityIcon,
  faCity as ValetAreaIcon,
  faLayerGroup as AreaIcon,
  faCity as TenantIcon,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faTabletAlt as DeviceIcon,
  faArrowAltUp as GateUpIcon,
  faBan as ClosedIcon,
} from "@fortawesome/pro-solid-svg-icons";

import { faCircleQuestion as QueryingIcon } from "@fortawesome/pro-regular-svg-icons";

import { faBan as DisabledIcon } from "@fortawesome/pro-light-svg-icons";
import { faWarehouseFull as FacilityGroupIcon } from "@fortawesome/pro-regular-svg-icons";
import { faWifiSlash as notListeningIcon } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useStyles } from "./EntityAvatar.styles";
import _ from "lodash";

import { FindEntity } from "../../../state/slices/entities";
import * as c from "../../../constants";
import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectOrgTreeEntityById} from "../../../state/slices/OrgTree";

const EntityAvatar = ({ entityID, iconSize, disabled, isBeingListened }) => {
  const classes = useStyles();
  const useCoreEntitySlice = useCoreEntityContext();

  const entity =
    useSelector((state) => {
      let _entity =useCoreEntitySlice ? selectEntityById(state, entityID) : FindEntity(state.entities?.EntityList ?? [], entityID);

      if(useCoreEntitySlice && !_entity?.entityid){
        _entity = selectOrgTreeEntityById(state, entityID);
      }
      return {
        entityid: _entity?.entityid ?? null,
        name: _entity?.name ?? null,
        type: _entity?.type ?? null,
        typeid: _entity?.typeid ?? null,
        online: (_entity?.state != null ? true : false) ?? false,
        gateOpen: _entity?.state?.Peripherals?.Gate?.Open ?? false,
        armingLoop:
          _entity?.state?.Peripherals?.Gate?.ArmingLoop ?? c.LOOP_STATUS.Unset, //0 unknown, 1 active, 2 inactive
        closingLoop:
          _entity?.state?.Peripherals?.Gate?.ClosingLoop ?? c.LOOP_STATUS.Unset, //0 unknown, 1 active, 2 inactive
        deviceMode:
          _.find(_entity?.settings ?? [], { name: "devicemode" })?.value ??
          null,
        closed:
          (_.find(_entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
          "true"
            ? true
            : _entity?.state?.Disabled) ?? false,
        reverseOffline: _entity?.state?.ReversingLane?.ReverseOffline,
      };
    }, shallowEqual) ?? {};

  useEffect(() => {
    //if user or entity changes
    //console.log("EntityAvatar:state change on ", entityID, entity);
    return () => {};
  }, [entityID, entity]);

  return (
    <>
      <Avatar
        className={clsx(
          classes.root,
          iconSize == "xs" ? classes.small : "",
          getEntityStatusClass(entity, classes, isBeingListened)
        )}
        data-testid={entity.entityid}
        data-state-error={false}
        aria-label={entity.name}
        title={
          entity.armingLoop === c.LOOP_STATUS.Active ||
          entity.closingLoop === c.LOOP_STATUS.Active
            ? "Vehicle in Lane"
            : entity.closed
            ? "Lane Closed"
            : entity.entityid
        }
      >
        <>
          {
            {
              device: (
                <span
                  className={classNames(
                    classes.iconWrapper,
                    "device fa-layers fa-fw"
                  )}
                >
                  <FontAwesomeIcon
                    icon={entity.gateOpen ? GateUpIcon : DeviceIcon}
                    size={iconSize}
                  />
                  {(entity.closed === true ||
                    entity.reverseOffline === true) && (
                    <FontAwesomeIcon
                      data-testid="ban"
                      icon={ClosedIcon}
                      size={iconSize}
                      className={classes.closedIcon}
                      style={{ marginLeft: -8 }}
                    />
                  )}
                  {!entity.online && !disabled && (
                    <>
                      {isBeingListened && (
                        <FontAwesomeIcon
                          beatFade
                          icon={QueryingIcon}
                          className={classes.iconQuerying}
                          style={{ marginLeft: -8 }}
                        />
                      )}
                    </>
                  )}
                  {disabled && (
                    <FontAwesomeIcon
                      icon={DisabledIcon}
                      className={classes.iconDisabled}
                      style={{ marginLeft: -9 }}
                    />
                  )}
                  {!isBeingListened && (
                    <FontAwesomeIcon
                      data-testid="not-listening-icon"
                      beatFade
                      icon={notListeningIcon}
                      className={classes.listeningIcon}
                    />
                  )}
                </span>
              ),
              area: <FontAwesomeIcon icon={AreaIcon} size={iconSize} />,
              tenant: <FontAwesomeIcon icon={TenantIcon} size={iconSize} />,
              facility: <FontAwesomeIcon icon={FacilityIcon} size={iconSize} />,
              valetarea: (
                <FontAwesomeIcon icon={ValetAreaIcon} size={iconSize} />
              ),
              facilitygroup: (
                <FontAwesomeIcon icon={FacilityGroupIcon} size={iconSize} />
              ),
            }[entity.type?.toLowerCase()]
          }
        </>
      </Avatar>
    </>
  );
};

const getEntityStatusClass = (entity, classes, islistening) => {
  if (!entity.online) {
    return classes.querying;
  }

  switch (true) {
    case entity.typeid === c.ENTITY_TYPE.Device && !islistening:
      return classes.querying;
    case entity.armingLoop === c.LOOP_STATUS.Active && !entity.reverseOffline:
      return classes.armed;

    case entity.closed || entity.reverseOffline:
      return classes.closed;

    default:
      return classes.queried;
  }
};

EntityAvatar.defaultProps = {
  entityID: null,
  iconSize: "1x",
  isBeingListened: true,
};
EntityAvatar.propTypes = {
  entityID: PropTypes.string,
  iconSize: PropTypes.string,
  isBeingListened: PropTypes.bool,
};

export default EntityAvatar;
