// Utility function to build a tree from the coreEntity.entities slice
import * as c from "../../../constants";
import _ from "lodash";
import {DetermineIssuesOnGivenEntity} from "../entities";

export const BuildTree = (slice) => {
    // Ensure entities exist
    const validSlice = slice || {};
    const {entities, ids} = validSlice;

    // Create an entity map and initialize children array
    const entityMap = ids.reduce((acc, id) => {
        acc[id] = { ...entities[id], children: [] };
        return acc;
    }, {});

    const rootNodes = [];

    // Populate the parent-child relationship using ids for controlled iteration
    ids.forEach((id) => {
        const entity = entityMap[id];
        if (entity && entity.context) { // Ensure entity exists and context is true
            if (entity.parententityid) {
                const parent = entityMap[entity.parententityid];
                if (parent) {
                    parent.children.push(entity);
                }
            } else {
                rootNodes.push(entity); // No parent implies it's a root node
            }
        }
    });

    return rootNodes[0]; // Return the first root node as specified
};

export function findNearestFacilityGroupFromEntity(entities, entityid) {
    let current = entities[entityid];
    let lastFound = null;
    while (current) {
        if (current.typeId === c.ENTITY_TYPE.FacilityGroup) {
            lastFound = current;
        }
        current = entities[current.parententityid]; // Move up the hierarchy
    }

    return lastFound;
}

export function findNearestFacilityFromEntity(entities, entityid) {
    let current = entities[entityid];
    let lastFound = null;
    while (current) {
        if (
            'typeid' in current && (current.typeid === c.ENTITY_TYPE.Facility || current.typeid === c.ENTITY_TYPE.ValetArea) ||
            'typeId' in current && (current.typeId === c.ENTITY_TYPE.Facility || current.typeId === c.ENTITY_TYPE.ValetArea)
        ) {
            lastFound = current;
        }
        current = entities[current?.parentEntityId ?? current?.parententityid]; // Move up the hierarchy
    }
    return lastFound;
}

export const findEntityForTree = (entities, entityId) => {
    const entity = entities[entityId];
    if(!entity){
        return null;
    }
    const hasChildren = Object.values(entities).some((e) => e.parententityid === entity?.entityid);
    return {
        parentEntityID: entity?.parententityid,
        entityID: entity?.entityid,
        name: entity?.name,
        typeID: entity?.typeid,
        hasChildren,
        lotFull:
            _.find(entity?.settings ?? [], { name: "displaylotfull" })?.value ==
            "auto"
                ? entity?.state != null && entity?.state?.Occupancy?.Full == true //if auto, is occupancy full or not?
                : _.find(entity?.settings ?? [], { name: "displaylotfull" })
                    ?.value == "on"
                    ? true
                    : false, //if manual, is it on or not?
        laneClosed:
            _.find(entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
            "true",
        disabled: entity?.state?.Disabled ?? false,
        deviceMode:
            _.find(entity?.settings ?? [], { name: "devicemode" })?.value ?? null,
        reverseOffline: entity?.state?.ReversingLane?.ReverseOffline,
        context: entity?.context ?? false
    };
}

export function applyPermissions(permissionDict, node) {
    const permissions = permissionDict[node.entityid];
    if (permissions) {
        return { ...node, permissions };
    }
    if (node.children) {
        return {
            ...node,
            children: node.children.map((childNode) =>
                applyPermissions(permissionDict, childNode)
            ),
        };
    }
    return node;
}


