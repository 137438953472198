import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";

const DeviceControlV2Page = props => {
    const { layoutReducer } = useThemeContext();
    const [, layoutDispatch] = layoutReducer;

    useEffect(() => {
        layoutDispatch({ type: setRootContainerWidth, payload: false });
    }, [layoutDispatch]);
    return (
        <></>
    );
};

export default DeviceControlV2Page;